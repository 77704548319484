//Copyright by Chaafo pvt ltd

import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types'; 

function CartDropdownHeader(props) {
	
		return (

			<div className={props.className}>
				{
					props.image?
					<img alt={props.imageAlt} src={props.image} className={props.imageClass} />:''
				}
	             <h6 className="mb-0">{props.title}</h6>
				{
					props.subTitle?
	             	<p className="text-secondary mb-0">{props.subTitle}</p>:''
	            }
	            {
					props.linkUrl?
		             <small>
		             	<Link className="text-primary font-weight-bold" to={props.linkUrl}>{props.linkText}</Link>
	             	</small>:''
	            }
	        </div>
		);
	}

export default CartDropdownHeader;