//Copyright by Chaafo pvt ltd


import React from 'react';
import PropTypes from 'prop-types';
import { SiteColors } from '../../constants/colorTheme';

function SectionHeading(props) {

  return (
    <div className={`section-header ${props.alignClass}`} >
      <h2 style={{ color: SiteColors.textSecondary }}>{props.heading}</h2>
      {props.subHeading ? (
        <p>{props.subHeading}</p>
      ) : ''
      }
      <span className="line"></span>
    </div>
  );
}

SectionHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  alignClass: PropTypes.string,
};
SectionHeading.defaultProps = {
  subHeading: '',
  alignClass: 'text-center',
}

export default SectionHeading;