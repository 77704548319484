//Copyright by Chaafo pvt ltd


import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Form, InputGroup, Dropdown, Button, Tab, Nav, Image, Badge } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Merchantkifile from './common/Merchantkifile';

import { Helmet } from 'react-helmet';

const Merchants = () => {

    const [bannerKiKey, setBannerKiKey] = useState([]);
    const history = useHistory('')

    useEffect(() => {
        const merchantkeys = () => {
            fetch("https://chaafo.com/singlemerchant/api/flutter_getAppSettings?merchant_keys=b35b284be8ea82c758ddd14754d91933")
                .then(response => response.json())
                .then(result => {
                    if (result.code == 1) {
                        setBannerKiKey(result.details.merchant_group);

                    }
                })
        }
        merchantkeys();
    }, []);


    return (
        <div>
             <Helmet>
             <meta name="robots" content="noindex, nofollow" />
              </Helmet>
            <Container>
                <Row>
                    <Col md={12}>

                        <h1 className='text-center'>Eat Factory</h1>
                        <hr></hr>
                        <Row>
                            {(bannerKiKey?.map((banner) =>
                                <Col md={3} sm={6} className="mb-4 pb-2 zoom">
                                    <Merchantkifile
                                        id={banner.Id}
                                        title={banner.merchant_name}
                                        mykey={banner.merchant_key}
                                        image={banner.merchant_banner}
                                        imageClass='list item-img'
                                    />
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )}

export default Merchants;