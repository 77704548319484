
import React, { useEffect, useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Container, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { urls } from './URLs';
import GlobalContext_ from '../GlobalContext';
import { Helmet } from 'react-helmet';

function MobileVerification() {

    const {MK} = useContext(GlobalContext_);
    const initialValues = { first_name: "", contact_phone: "", email_address: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const history = useHistory();
    useEffect(() => {
        if (localStorage.getItem('user_info')) {

        }
    }, [MK])

    toast.configure();

    //Function to register user using mobile number
    async function customer_register_mobile() {

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en");

        var formdata = new FormData();
        formdata.append("first_name", formValues.first_name);
        formdata.append("contact_phone", formValues.contact_phone);
        formdata.append("email_address", formValues.email_address);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let result = await fetch(urls.RegisterMobile + "?next_step2=firsttime&contact_phone=" + formValues.contact_phone + "&merchant_keys=" + MK, requestOptions, {
        });

        result = await result.json();
        sessionStorage.setItem("keycheck", result.details.token);

        if (result.code === 1) {
            history.push('/otp_mobile');
            localStorage.setItem("first_name", formValues.first_name);
            localStorage.setItem("contact_phone", formValues.contact_phone);
            localStorage.setItem("email_address", formValues.email_address);
        }
        else {
            toast.error(result.msg, {
                position: "top-center",
                autoClose: 600
            });
            history.push("/MobileVerification")
        }
    }

    //Function to handle changes while registration
    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormValues({ ...formValues, [name]: value });
    // };

    //Function to handle errors while submitting the details to register
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    };

    //Function which proceeds to the successful registration if there aren't any errors
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            customer_register_mobile();
        }
    }, [formErrors, isSubmit]);


    //Function to validate errors
    const validate = (values) => {
        const errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const nameRegex = /^[A-Za-z]+$/; // Regex to check for alphabetic characters only

        // Validate first name
        if (!values.first_name) {
            errors.first_name = "*First name is required!";
        } else if (!nameRegex.test(values.first_name)) {
            errors.first_name = "*First name can only contain alphabets!";
        }

        // Validate contact phone
        if (!values.contact_phone) {
            errors.contact_phone = "*Mobile no. is required!";
        } else if (values.contact_phone.length !== 10) {
            errors.contact_phone = "Enter valid Mobile No.";
        } else if (isNaN(values.contact_phone)) {
            errors.contact_phone = "Enter valid Mobile No.";
        }

        // Validate email address
        if (!values.email_address) {
            errors.email_address = "*Email is required!";
        } else if (!emailRegex.test(values.email_address)) {
            errors.email_address = "This is not a valid email format!";
        }

        return errors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Validate and update formValues
        if (name === 'first_name') {
            // Remove non-alphabetic characters
            const filteredValue = value.replace(/[^a-zA-Z]/g, '');
            setFormValues({
                ...formValues,
                [name]: filteredValue,
            });
        } else {
            setFormValues({
                ...formValues,
                [name]: value,
            });
        }
    };

    return (
        <>
           
            <Container fluid className='bg-white'>

                <Row>
                    <Col md={4} lg={6} className="d-none d-md-flex bg-image"></Col>
                    <Col md={8} lg={6}>
                        <h3 className="login-heading mt-5 text-center">ch<span>A</span>afo</h3>
                        <div className="login align-items-center mt-5 mb-4">
                            <Container>
                                <Row>

                                    <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
                                        <form>
                                            <div className="form-label-group mb-4">
                                                <Form.Control
                                                    type="text"
                                                    name="first_name"
                                                    value={formValues.first_name}
                                                    required
                                                    autoComplete='off'
                                                    onChange={handleChange}
                                                    onKeyDown={(e) => {
                                                        // Allow only alphabetic characters and some special keys like Backspace and Arrow keys
                                                        if (!/^[a-zA-Z]*$/.test(e.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab'].includes(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    id="first_name"
                                                    placeholder="First Name"
                                                />
                                                <Form.Label htmlFor="first_name">Full Name</Form.Label>
                                            </div>

                                            <p className='Val'>{formErrors.first_name}</p>
                                            <div className="form-label-group mb-4">
                                                <Form.Control type="number" name="contact_phone" value={formValues.contact_phone} required autoComplete='off' onChange={handleChange} id="contact_phone" maxLength={10} placeholder="Mobile No." />
                                                <Form.Label htmlFor="contact_phone">Mobile No.</Form.Label>
                                            </div>
                                            <p className='Val'>{formErrors.contact_phone}</p>
                                            <div className="form-label-group">
                                                <Form.Control type="email" name="email_address" value={formValues.email_address} required autoComplete='off' onChange={handleChange} id="email_address" placeholder="Email address" />
                                                <Form.Label htmlFor="email_address">Email address</Form.Label>
                                            </div>
                                            <p className='Val'>{formErrors.email_address}</p>
                                            <Link className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2" onClick={handleSubmit}>Send Otp</Link>
                                        </form>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );

}

export default MobileVerification;