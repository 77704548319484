//Copyright by Chaafo pvt ltd


import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import Icofont from 'react-icofont';
import CartDropdownItem from './cart/CartDropdownItem';
import { useState, useEffect, useContext } from 'react';
import { urls } from './URLs';
import GlobalContext_ from '../GlobalContext';
import { Helmet } from 'react-helmet';

function TrackOrder() {
	var T = localStorage.getItem("tokenn");

	const { MK, restaurantinfo } = useContext(GlobalContext_);
	var ORDER_ID = localStorage.getItem("ORDER_ID");

	const [trackorder, setTrackorder] = useState([]);
	const [trackorder1, setTrackorder1] = useState('');
	const [OrderDetails1, setOrderDetails1] = useState([]);
	const [total, setTotal] = useState('');


	// to get orders history and past orders
	useEffect(() => {
		async function track() {
			const response = await fetch(urls.TrackOrder + '?id=' + ORDER_ID + '&merchant_keys=' + MK + '&device_id=' + T + '&device_platform=web&token=' + T + '&lang=en&_=1647494208716');
			const data1 = await response.json();
			setTrackorder(data1.details.data);
			setTrackorder1(data1.details.order_id);
		}

		track()
	},
		[MK]);

	// to get order details for generating invoice
	useEffect(() => {
		async function invoice() {
			var myHeaders = new Headers();
			myHeaders.append("Cookie", "_lang=en");

			var formdata = new FormData();
			formdata.append("token", T);
			formdata.append("id", ORDER_ID);

			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			};

			fetch(urls.Invoice + "?merchant_keys=" + MK, requestOptions)
				.then(response => response.json())
				.then(result1 => (setOrderDetails1(result1.details.htmlarray), setTotal(result1.details.cartamount)))
		}
		invoice()
	},
		[MK]);

	return (
		<>
			<Helmet>
			<meta name="robots" content="noindex, nofollow" />
				</Helmet>
			<section className="section bg-white osahan-track-order-page position-relative">
				{ORDER_ID ?
					<div>
						<iframe title="Address" className="position-absolute" src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d13696.650704896498!2d75.82434255!3d30.8821099!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1555184720550!5m2!1sen!2sin" width="100%" height="676" frameBorder="0" style={{ border: 0 }} allowFullScreen=""></iframe>
						{/* <i class="icofont-location-pin"></i> */}
						<Container className="pt-5 pb-5">
							<Row className="d-flex align-items-center">
								<Col md={6} className="text-center pb-4">
									<div className="osahan-point mx-auto"></div>
								</Col>
								<Col md={6}>
									<div className="bg-white p-4 shadow-lg mb-2">
										<div className="mb-2"><small>Order {trackorder1}<Link className="float-right font-weight-bold" to="/Invoice"><Icofont icon="clip-board" /> Invoice</Link></small></div>
										<h6 className="mb-1 mt-1">
											<Link to="/detail" className="text-black">{restaurantinfo.merchant_name}
											</Link>
										</h6>
										{/* <p className="text-gray mb-0"><Icofont icon="clock-time"/> 04:19 PM | 8 Items | $314</p> */}
									</div>
									<div className="bg-white p-4 shadow-lg">
										<div className="osahan-track-order-detail po">
											<h5 className="mt-0 mb-3">Order Details</h5>
											<Row>
												{/* <Col md={5}>
	                              <small>FROM</small>
	                              <h6 className="mb-1 mt-1"><Link to="/detail" className="text-black"><Icofont icon="food-cart"/> Spice Hut Restaurant
	                                 </Link>
	                              </h6>
	                              <p className="text-gray mb-5">2036, NEW YORK</p>
	                              <small>DELIVER TO</small>
	                              <h6 className="mb-1 mt-1"><span className="text-black"><Icofont icon="map-pins"/> Other
	                                 </span>
	                              </h6>
	                              <p className="text-gray mb-0">291, Jawaddi Kalan, Ludhiana, Punjab 141002, India
	                              </p>
	                           </Col> */}
												<Col md={12}>
													<div className="mb-2"><small><Icofont icon="list" />ITEMS</small></div>
													{(OrderDetails1?.map(order =>
														<CartDropdownItem
															icoIcon='ui-press'
															iconClass='text-success food-item'
															title={order.item_name}
															qty={order.qty}
															price={order.price}
														/>
													))}

													<hr />
													<p className="mb-0 font-weight-bold text-black">TOTAL BILL  <span className="float-right text-secondary">{total?.cart_total}</span></p>
													{/* <p className="mb-0 text-info"><small>Paid via Credit/Debit card
	                                 <span className="float-right text-danger">$620 OFF</span></small>
	                              </p> */}
												</Col>
											</Row>
										</div>
									</div>
									<div className="bg-white p-4 shadow-lg mt-2">
										<Row className="text-center">
											{trackorder?.map(track =>
												<Col>
													<Icofont icon="tasks" className="icofont-3x text-info" />
													<p className="mt-1 font-weight-bold text-dark mb-0">{track.status}</p>
													<small className="text-info mb-0">{track.date}</small>
												</Col>
											)}
											{/* <Col>
	                        	<Icofont icon="check-circled" className="icofont-3x text-success"/>
	                           <p className="mt-1 font-weight-bold text-dark mb-0">Order Confirmed</p>
	                           <small className="text-success mb-0">NEXT</small>
	                        </Col>
	                        <Col>
	                        	<Icofont icon="delivery-time" className="icofont-3x text-primary"/>
	                           <p className="mt-1 font-weight-bold text-dark mb-0">Order Picked Up</p>
	                           <small className="text-primary mb-0">LATER (ET : 30min)</small>
	                        </Col> */}
										</Row>
									</div>
								</Col>
							</Row>
						</Container>
					</div> : <div></div>}
			</section>
		</>

	);
}



export default TrackOrder;