//Copyright by Chaafo pvt ltd


import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Container, Image } from 'react-bootstrap';
import { GlobalContext } from './GlobalState';
import { urls } from './URLs';
import GlobalContext_ from '../GlobalContext';
import { Helmet } from 'react-helmet';

function Thanks() {
	var T = localStorage.getItem("tokenn");

	const { MK } = useContext(GlobalContext_);
	const [sts, setSts] = useState('');
	const history = useHistory('');
	const [trackorder, setTrackorder] = useState([]);
	const [leng, setlength] = useState(0);
	var ORDER_ID = localStorage.getItem("ORDER_ID");

	// setTimeout function is made to hide cancel order button
	useEffect(() => {
		setTimeout(() => {
			const box = document.getElementById('hide');
			box.style.display = 'none';
		}, 15000)

	}, [MK])


	const Ref = useRef(null);

	//using contexts made in App.js 
	const { cart, setCart, setTotal, setCount, setEmpty, setPay } = useContext(GlobalContext);

	toast.configure();

	// The state for our timer shown in cancel order button
	const [timer, setTimer] = useState('00:00:00');


	const getTimeRemaining = (e) => {
		const total = Date.parse(e) - Date.parse(new Date());
		const seconds = Math.floor((total / 1000) % 60);
		// const minutes = Math.floor((total / 1000 / 60) % 60);
		// const hours = Math.floor((total / 1000 * 60 * 60) % 24);
		return {
			total, seconds
		};
	}


	const startTimer = (e) => {
		let { total, seconds }
			= getTimeRemaining(e);
		if (total >= 0) {

			// update the timer
			// check if less than 10 then we need to 
			// add '0' at the begining of the variable
			setTimer(
				(seconds > 9 ? seconds : '0' + seconds)
			)
		}
	}


	const clearTimer = (e) => {

		// If you adjust it you should also need to
		// adjust the Endtime formula we are about
		// to code next    
		setTimer('15');

		// If you try to remove this line the 
		// updating of timer Variable will be
		// after 1000ms or 1sec
		if (Ref.current) clearInterval(Ref.current);
		const id = setInterval(() => {
			startTimer(e);
		}, 1000)
		Ref.current = id;
	}

	const getDeadTime = () => {
		let deadline = new Date();

		// This is where you need to adjust if 
		// you entend to add more time
		deadline.setSeconds(deadline.getSeconds() + 15);
		return deadline;
	}

	// We can use useEffect so that when the component
	// mount the timer will start as soon as possible

	// We put empty array to act as componentDid
	// mount only
	useEffect(() => {
		clearTimer(getDeadTime());
	}, []);

	// Another way to call the clearTimer() to start
	// the countdown is via action event from the
	// button first we create function to be called
	// by the button

	// to get orders history and past orders
	useEffect(() => {
		async function track() {
			const response = await fetch(urls.TrackOrder + '?id=' + ORDER_ID + '&merchant_keys=' + MK + '&device_id=' + T + '&device_platform=web&token=' + T + '&lang=en&_=1647494208716');
			const data1 = await response.json();
			if (data1.details.data.length >= 2) {
				setSts('1');
			}
		}

		track()
	},
		[MK]);

	//sending request to backend for cancelling order
	const Cancel = async () => {
		const response = await fetch(urls.CancelOrder + '?order_id=' + ORDER_ID + '&token=' + T + '&merchant_keys=' + MK);
		const data = await response.json();
		if (data.code == 1) {
			toast.success(data.msg, {
				position: "top-center",
				autoClose: 400
			});
			history.push('/');
		}
	}

	return (
		<>
			<Helmet>
			<meta name="robots" content="noindex, nofollow" />
			</Helmet>
			<section className="section pt-5 pb-5 osahan-not-found-page">
				<Container>
					<Row>
						<Col md={12} className="text-center pt-5 pb-5">
							<Image alt="404" className="img-fluid" src="/img/thanks.png" />
							<h1 className="mt-2 mb-2">Congratulations</h1>
							<p className="mb-5">You have successfully placed your order</p>
							<Link className="btn btn-success btn-lg" to="/track-order">Track Order</Link>
							<h><span> </span></h>
							{sts !== '1' ?
								<Link className="btn btn-success btn-lg" id='hide' onClick={Cancel}>Cancel Order in {timer}s</Link>
								: " "}
						</Col>
					</Row>
				</Container>
			</section>
		</>
	);
}

export default Thanks;