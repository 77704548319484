//Copyright by Chaafo pvt ltd
//Change Password modal appears when user want to change his/her password


import React, { useState, useContext, useEffect } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { MERCHANTKEY } from '../../App';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';

function ChangePassword(props) {
    const {MK} = useContext(GlobalContext_);
    var T = localStorage.getItem("tokenn");
    const initialValues = { password: "", cpassword: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [hide, setHIde] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);
    toast.configure()
        //Function to change password
        const EditProfile=()=> {
            
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "_lang=en");

            var formdata = new FormData();
            formdata.append("password", formValues.password);
            formdata.append("cpassword", formValues.cpassword);
            formdata.append("token", T);
            

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(urls.ChangePassword+"?merchant_keys="+MK, requestOptions)
            .then(resp=>resp.json())
            .then(result=>{
                if(result.code==1){
                    submitOntrue();
                    toast.success(result.msg, {
                        position: "top-center",
                        autoClose: 400
                     });
                }else{
                    toast.error(result.msg, {
                        position: "top-center",
                        autoClose: 500
                     });
                }
            })         
        }

        //Function to handle changes in fields of change password modal while editing
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
        };
        
        //Function to handle errors while submitting the password to get saved
        const handleSubmit = (e) => {
            e.preventDefault();
            setFormErrors(validate(formValues));
            setIsSubmit(true);
    
        };
    
        const submitOntrue = () => {
            if (Object.keys(formErrors).length === 0 && isSubmit) {
                setHIde(props.onHide);
                return props.onHide;
            }
        }
    
        useEffect(() => {
            if (Object.keys(formErrors).length === 0 && isSubmit) {
                EditProfile();
            }
        }, [formErrors]);
        
        //Function to validate errors
        const validate = (values) => {
            const errors = {};

            if (!values.password) {
                errors.password = "*Password is required";
            } else if (values.password.length < 5) {
                errors.password = "Password must be more than 5 characters";
            } else if (values.password.length > 20) {
                errors.password = "Password cannot exceed more than 20 characters";
            }
            if (values.cpassword != values.password) {
                errors.cpassword = "Password and Confirm password does not match";
            }
            return errors;
        };

        
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="sm"
            centered
        >
            <Modal.Header closeButton={true}>
                <Modal.Title as='h5' id="edit-profile">Change Password</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <div className="form-row">
                        <Form.Group className="col-md-12 mb-0">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control 
                            type="New password" 
                            name='password' 
                            value={formErrors.password}
                            onChange={handleChange} 
                            placeholder="Enter new password
                        "/>
                        </Form.Group>
                        <p className='Val'>{formErrors.password}</p>
                        <Form.Group className="col-md-12 mb-0">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control 
                            type="Confirm password"
                             name='cpassword' 
                             value={formErrors.cpassword}
                              onChange={handleChange} 
                              placeholder="Confirm password"/>
                        </Form.Group>
                        <p className='Val'>{formErrors.cpassword}</p>
                    </div>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button type='button' onClick={props.onHide} variant="outline-primary" className="d-flex w text-center justify-content-center">CANCEL</Button>
                
                <Button type='button' onClick={handleSubmit}  variant="primary" className='d-flex w text-center justify-content-center' >UPDATE</Button>
                
            </Modal.Footer>
        </Modal>

    );
}

export default ChangePassword;