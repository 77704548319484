import React, { createContext, useContext, useState } from 'react';

const ScrollContext = createContext();

const ScrollProvider = ({ children }) => {
  const [activeCategory, setActiveCategory] = useState(null);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setActiveCategory(sectionId);
    }
  };

  return (
    <ScrollContext.Provider value={{scrollToSection, activeCategory, setActiveCategory }}>
      {children}
    </ScrollContext.Provider>
  );
};

export { ScrollProvider, ScrollContext };
