//Copyright by Chaafo pvt ltd
//MyPoints section is given in account section to show total, expenses and axpired points

import React, { useState, useEffect, useContext } from 'react';
import { Col, Row, Nav, Tabs, Tab } from 'react-bootstrap';
import '../../App.css';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';

import { Helmet } from 'react-helmet';

function Mypoints() {
  const { MK } = useContext(GlobalContext_);
  var T = localStorage.getItem("tokenn");
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [expired, setExpired] = useState([]);
  const [expiredcode, setExpiredcode] = useState(0);
  const [merchantpoint, setMerchantPoint] = useState('');


  useEffect(() => {

    //to load points in cart in realtime
    async function Loadcart() {
      var myHeaders = new Headers();
      myHeaders.append("Cookie", "_lang=en");

      var formdata = new FormData();
      formdata.append("token", T);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      //to show loyalty points summary
      fetch(urls.PointsSummary + "?merchant_keys=" + MK, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.code === 1) {
            setCount(result.details);
          }
          else {
            setCount('');
          }

        })

      //to show earned points by the user pre order
      fetch(urls.EarnPoints + "?merchant_keys=" + MK + "&token=" + T)
        .then(response => response.json())
        .then(result => {
          if (result.code === 1) {
            setData(result.details.data);
          }
          else {
            setData([]);
          }

        })

      //to show expense points of the user
      fetch(urls.ExpensePoints + "?merchant_keys=" + MK, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.code === 1) {
            setExpenses(result.details.data);

          }
          else {
            setExpenses([]);
          }

        })

      //to show expired points of the user
      fetch(urls.ExpiredPoints + "?merchant_keys=" + MK + "&token=" + T)
        .then(response => response.json())
        .then(result => {
          if (result.code === 1) {
            setExpiredcode(result.code);
            setExpired(result.details.data);

          }
          else {
            setExpired([]);
          }

        })

      //to show points earned by the merchant
      fetch(urls.MerchantearnPoints + "?merchant_keys=" + MK + "&token=" + T)
        .then(response => response.json())
        .then(result => {
          if (result.code === 1) {
            setMerchantPoint(result.details.data[0]);
          }
          else {
            setMerchantPoint("");
          }

        })
    }
    Loadcart();
  }, [MK])

  return (
    <>
      <Helmet>
      <meta name="robots" content="noindex, nofollow" />
       </Helmet>
      <div className="col-md-12 grid-margin stretch-card">
        {count.total_available ?
          <div className="card">
            <div className="card-body">
              <div className="card">
                <div className="card-body">
                  <div className="d-sm-flex justify-content-center justify-content-sm-between">
                    <div>
                      <p>Available Points</p>
                      <h4>{count.total_available}</h4>
                    </div>
                    {expiredcode === 1 ?
                      <div>
                        <p>Expired Points</p>
                        {expired.map(expire =>
                          <h4><td>{expire.points}</td></h4>
                        )}
                      </div>
                      : " "
                    }
                  </div>
                </div>
              </div>

              <br></br>
              <Tabs defaultActiveKey="Income Points" id="uncontrolled-tab-example">
                <Tab eventKey="Income Points" title="Income Points" className="test-tab">
                  <br></br>
                  <div className="card">
                    <div className="card-body">
                      <div className="d-sm-flex justify-content-center justify-content-sm-between">

                        <table className='table'>

                          <tr>
                            <th>Date</th>
                            <th>Transaction</th>
                            <th>Amount</th>
                          </tr>
                          {data?.map(date =>
                            <tr>
                              <td>{date.date}</td>
                              <td>{date.label}</td>
                              <td>{date.points}</td>
                            </tr>
                          )}

                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="preview-item-content d-sm-flex flex-grow">
                    <div className="flex-grow">
                    </div>
                    <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                    </div>
                  </div>

                </Tab>
                <Tab eventKey="Expenses Points" title="Expenses Points">

                  <br></br>
                  <div className="card">
                    <div className="card-body">
                      <div className="d-sm-flex justify-content-center justify-content-sm-between">

                        <table className='table'>

                          <tr>
                            <th>Date</th>
                            <th>Transaction</th>
                            <th>Amount</th>
                          </tr>
                          {expenses?.map(expense =>
                            <tr>
                              <td>{expense.date}</td>
                              <td>{expense.label}</td>
                              <td>{expense.points}</td>
                            </tr>
                          )}


                        </table>
                      </div>
                    </div>
                  </div>
                </Tab>
                {expiredcode === 1 ?
                  <Tab eventKey="Expired Points" title="Expired Points">

                    <br></br>
                    <div className="card">
                      <div className="card-body">
                        <div className="d-sm-flex justify-content-center justify-content-sm-between">

                          <table className='table'>

                            <tr>
                              <th>Date</th>
                              <th>Transaction</th>
                              <th>Amount</th>
                            </tr>

                            {expired?.map(expire =>
                              <tr>
                                <td>{expire.date}</td>
                                <td>{expire.label}</td>
                                <td>{expire.points}</td>
                              </tr>
                            )}




                          </table>
                        </div>
                      </div>
                    </div>
                  </Tab> :
                  " "}
                <Tab eventKey="Points By Merchant" title="Points By Merchant">

                  <br></br>
                  <div className="card">
                    <div className="card-body">
                      <div className="d-sm-flex justify-content-center justify-content-sm-between">

                        <table className='table'>

                          <tr>
                            <th>Merchant Name</th>
                            <th>Points</th>
                            {/* <th>Amount</th> */}
                          </tr>

                          <tr>
                            <td>{merchantpoint.restaurant_name}</td>
                            <td>{merchantpoint.points}</td>

                          </tr>



                        </table>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div> :
          <div>No points availbale</div>}
      </div>

    </>


  )
}




export default Mypoints;
