//Copyright by Chaafo pvt ltd
//Ls file is called for checking payment status


import React, { useEffect, useContext } from "react";
import ReactLoading from "react-loading";
import { GlobalContext } from './GlobalState';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { urls } from "./URLs";
import GlobalContext_ from "../GlobalContext";
import { Helmet } from 'react-helmet';

function Ls() {

    const { MK } = useContext(GlobalContext_);
    var T = localStorage.getItem("tokenn");
    var order_id = localStorage.getItem("ORDER_ID");
    const history = useHistory();
    const { cart, setCart, setTotal, setCount, setEmpty, Pay, setPay } = useContext(GlobalContext);

    //Function to load the cart after payment successful or declined
    async function Loadcart() {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en");

        var formdata = new FormData();
        formdata.append("device_id", T);
        formdata.append("token", T);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(urls.LoadCart + "?merchant_keys=" + MK + "&token=" + T, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 1) {
                    setCart(result.details.data.item);
                    setTotal(result.details.data.total);
                    setCount(result.details.cart_details);
                    setEmpty('');
                    setPay(result.details);
                }
            })
    }

    //Function to check payment status
    useEffect(() => {
        const paysts = () => {

            fetch('https://chaafo.com/protected/vendor/PaytmKit/paymentupdatestatus.php?ORDER_ID=' + order_id)
                .then(response => response.json())
                .then(result => {

                    if (result.RESPCODE === "01") {
                        // setSts(result2.TXNID);

                        var myHeaders = new Headers();
                        myHeaders.append("Cookie", "_lang=en");

                        var formdata = new FormData();
                        formdata.append("RESPCODE", result.RESPCODE);
                        formdata.append("ORDERID", result.ORDERID);
                        formdata.append("TXNID", result.TXNID);
                        formdata.append("PAYMENTMODE", "paytm");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(urls.PaytmConfirm + "?merchant_keys=" + MK + "&token=" + T, requestOptions)
                            .then(response3 => response3.json())
                            .then(result3 => {
                                if (result3.code == 1) {
                                    history.push('/thanks');
                                    setCart(null);
                                    Loadcart();
                                }
                            })

                    }
                    else {
                        toast.error("Transaction Failed!", {
                            position: "top-center",
                            autoClose: 400
                        });
                        history.push('/checkout');
                    }

                })
        }
        paysts();
    }, [MK])

    return (
        <>
            
            <div className="d-flex justify-content-center load">
                <ReactLoading type="spinningBubbles" color="blue"
                    height={100} width={100} />
            </div>
        </>
    );
}

export default Ls;