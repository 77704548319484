//Copyright by Chaafo pvt ltd

import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';
import { Row, Col, Container, Image } from 'react-bootstrap';
import Offers from './myaccount/Offers';
import Orders from './myaccount/Orders';
import Mypoints from './myaccount/Mypoints';
import Addresses from './myaccount/Addresses';
import ChangePassword from './modals/ChangePassword';
import EditProfileModal1 from './modals/EditProfileModal1'
import { MERCHANTKEY } from '../App';
import { GlobalContext } from './GlobalState';
import { urls } from './URLs';
import GlobalContext_ from '../GlobalContext';
import { Helmet } from 'react-helmet';

function MyAccount(props) {

   const { MK } = useContext(GlobalContext_);
   var T = localStorage.getItem("tokenn");
   const [showEditProfile, setShow] = useState(false);
   const [showEditProfile1, setShow1] = useState(false);
   const { merchant, setMerchant } = useContext(GlobalContext);
   const history = useHistory();


   //Function to fetch user profile details
   useEffect(() => {
      async function Merchant() {

         var myHeaders = new Headers();
         myHeaders.append("Cookie", "_lang=en");

         var formdata = new FormData();
         formdata.append("token", T);

         var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
         };

         fetch(urls.UserProfile + "?merchant_keys=" + MK, requestOptions)
            .then(response => response.json())
            .then(result => {
               if (result.code == 1) {
                  setMerchant(result.details.data)
               } else {
                  alert("Not logged in");
                  history.push("/");
               }
            })
      }
      Merchant()
   }, [MK]);

   //Function to scroll the tab to top
   const scrollTotop = () => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth'
      });
   };

   const hideEditProfile = () => setShow(false);
   const hideEditProfile1 = () => setShow1(false);

   return (

      <GlobalContext.Consumer>

         {(state) => (
            <>
               <Helmet>
               <meta name="robots" content="noindex, nofollow" />
                </Helmet>
               <ChangePassword show={showEditProfile} onHide={hideEditProfile} />
               <EditProfileModal1 show={showEditProfile1} onHide={hideEditProfile1} />
               <section className="section pt-4 pb-4 osahan-account-page">
                  <Container>
                     <Row>
                        <Col md={3}>
                           <div className="osahan-account-page-left shadow-sm bg-white h-100">
                              <div className="border-bottom p-4">
                                 {T ?
                                    <div className="osahan-user text-center">
                                       <div className="osahan-user-media">
                                          <Image alt={state.merchant.avatar} className="mb-3 rounded-pill shadow-sm mt-1" src={state.merchant.avatar} />
                                          <div className="osahan-user-media-body">
                                             <h6 className="mb-2">{state.merchant.full_name}</h6>
                                             <p className="mb-1">{state.merchant.contact_phone}</p>
                                             <p>{state.merchant.email_address}</p>
                                             <p className="mb-0 text-black font-weight-bold"><Link to='#' onClick={() => setShow1(true)} className="text-primary mr-3 mb-2"> Edit Profile</Link></p>
                                             <p className="mb-0 text-black font-weight-bold"><Link to='#' onClick={() => setShow(true)} className="text-primary mr-3">Change Password</Link></p>

                                          </div>
                                       </div>
                                    </div>
                                    : ""}
                              </div>
                              <ul className="nav flex-column border-0 pt-4 pl-4 pb-4">
                                 <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" exact to="/myaccount/orders" onClick={scrollTotop} ><i className="icofont-food-cart"></i> Orders</NavLink>
                                 </li>
                                 <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" exact to="/myaccount/offers" onClick={scrollTotop}><i className="icofont-sale-discount"></i> Offers</NavLink>
                                 </li>
                                 <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" exact to="/myaccount/addresses" onClick={scrollTotop}><i className="icofont-location-pin"></i> Addresses</NavLink>
                                 </li>
                                 <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" exact to="/myaccount/Mypoints" onClick={scrollTotop}><i className="icofont-bars"></i>My Points</NavLink>
                                 </li>
                              </ul>
                           </div>
                        </Col>
                        <Col md={9}>
                           <Switch>
                              <Route path="/myaccount/orders" exact component={Orders} />
                              <Route path="/myaccount/offers" exact component={Offers} />
                              <Route path="/myaccount/addresses" exact component={Addresses} />
                              <Route path="/myaccount/Mypoints" exact component={Mypoints} />
                           </Switch>
                        </Col>
                     </Row>
                  </Container>
               </section>
            </>
         )}
      </GlobalContext.Consumer>
   );
}

export default MyAccount;