import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import GlobalContext_ from '../../GlobalContext';
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import Icofont from 'react-icofont';

import Review from '../common/Review';
import BestSeller from '../common/BestSeller';
import MayLikeItem from '../common/MayLikeItem';
import QuickBite from '../common/QuickBite';
import StarRating from '../common/StarRating';
import RatingBar from '../common/RatingBar';
import { GlobalContext } from '../GlobalState';

import { ScrollContext } from '../../ScrollContext';

import RatingsNReviews from '../childComponents/RatingsNReviews';

import { Helmet } from 'react-helmet';
import { useEffect } from 'react';

function OrderOnline({ search, getQty, Search, review, re_view, setRate_f, countreview, ORDER_ID, setReview, getStarValue }) {

	const  { activeCategory} = useContext(ScrollContext);

	const { items, recommended, category } = useContext(GlobalContext_);
	const [openItems, setOpenItems] = useState({}); // Object to track open/closed state for each item

	useEffect(() => {
		if (activeCategory) {
		  setOpenItems((prevOpenItems) => ({
			...prevOpenItems,
			[activeCategory]: true, // Open the category corresponding to activeCategory
		  }));
		}
	  }, [activeCategory]);

	const AccordianControl = (cat_id) => {
		setOpenItems({ ...openItems, [cat_id]: !openItems[cat_id] });
	};

	return (
		<GlobalContext.Consumer>
			{
				(state) => (
					<>
						<Helmet>
							<meta name="robots" content="noindex, nofollow" />
						</Helmet>
						<Form className="explore-outlets-search mb-4">
							<InputGroup>
								<Form.Control type="text" onChange={(event) => Search(event.target.value)} placeholder="Search for dishes..." />

								<InputGroup.Append>
									<Button type="button" variant="link" style={{ border: '1px solid lightgray' }} >
										<Icofont icon="search" />
									</Button>
								</InputGroup.Append>

							</InputGroup>
							<div>
								{
									search ?
										<div className='mt-4'>
											{
												<Row>
													{(search?.map((item) =>
														<Col md={4} sm={6} className="mb-4">
															<MayLikeItem
																title={item.item_name}
																subTitle={item.item_description || 'Indian • American • Italian'}
																price={item.prices.price}
																image={item.photo_url}
																id={item.item_id}
																getValue={getQty}
															// category_id={1}
															/>

														</Col>
													))}
												</Row>
											}
										</div>
									: " "
								}

							</div>
						</Form>
						{/* Recommended */}
						<Row >
							<h5 className="mb-4 mt-3 col-md-12">Recommended</h5>
							{(recommended?.map((recommend, Index) =>
								<Col md={4} sm={6} className="mb-4">
									<BestSeller
										qty={state?.cart?.find(cartItem => cartItem.item_id == recommend.item_id)?.qty ?? 0}
										id={recommend?.item_id}
										title={recommend?.item_name}
										subTitle={recommend.item_description || 'Indian • American • Italian'}
										imageAlt={recommend.photo}
										image={recommend.photo}
										imageClass='imgheight'
										price={recommend.prices.price}
										size={recommend.prices.size}
										discountprice={recommend.prices.discount_price}
										priceUnit='₹ '
										isNew={true}
										//showPromoted={true}
										promotedVariant='dark'
										favIcoIconColor='text-danger'
										// rating='3.1 (300+)'
										getValue={getQty}
										Index={Index}
										category_id={category}
									/>
								</Col>
							))}
						</Row>
						{/* Dropdown Section  */}{/* Categories */}
						<div >
						<Row >
							{items ? (
								items.map((item, itemIndex) => (
									<Col md={12} key={itemIndex} id={item.cat_id}> 
										<div className=''style={{ marginBottom: '1rem', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' ,borderRadius: '5px'}}>
											<div
											className='d-flex justify-content-between'
												style={{ padding: '1rem', borderBottom: '1px solid lightgrey' }}
												onClick={() => AccordianControl(item.cat_id)}
											>
												<h2 className='m-0' style={{ fontSize: '1rem', fontWeight: 'bold' }}>
													{item.category_name}
												</h2>
												{openItems[item?.cat_id] ? <FaChevronUp /> : <FaChevronDown />}
											</div>
											{openItems[item.cat_id] && ( // Only render content if open
												<div >
													{item.menu_items?.map((menu, menuIndex) => 
														<QuickBite
														state={state}
															key={menuIndex}
															id={menu?.item_id}
															title={menu.item_name}
															subTitle={menu.item_description || 'Indian • American • Italian'}
															price={Number(menu.prices.price)}
															priceUnit="₹ "
															getValue={getQty}
															category_id={Number(item.cat_id)}
															Index={menuIndex}
															qty={state?.cart?.find(cartItem => cartItem.item_id == menu.item_id)?.qty ?? 0}
															imageAlt={menu.photo}
															size={menu.prices.size}
															sizeId={menu.prices.size_id}
															// image={menu.photo}
															imageClass='imgheight'
															discountprice={menu.prices.discount_price}
															isNew={true}
															//showPromoted={true}
															promotedVariant='dark'
															favIcoIconColor='text-danger'
															// rating='3.1 (300+)'
														/>
													)}
												</div>
											)}
										</div>
									</Col>
								))
							) : (
								''
							)}
						</Row>
						</div>
					
						{/* Ratings N Reviews */}
						<Row >
							<Col md={12} >
								<div style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }} >
									<div className="bg-white rounded p-4 mb-4 clearfix graph-star-rating"  >
										<h5 className="">Ratings and Reviews</h5>
										<div className="star-rating d-flex justify-content-between">
											<StarRating fontSize={18} disabled={true} star={5} getValue={getStarValue} />
											<span >{countreview.review_count} customer ratings</span>
										</div>
										<hr style={{ background: 'lightgrey' }} />
										{/* </div> */}
										<div className="graph-star-rating-body">
											<RatingBar leftText="5 Star" barValue={countreview.percentage_5star} />
											<RatingBar leftText="4 Star" barValue={countreview.percentage_4star} />
											<RatingBar leftText="3 Star" barValue={countreview.percentage_3star} />
											<RatingBar leftText="2 Star" barValue={countreview.percentage_2star} />
											<RatingBar leftText="1 Star" barValue={countreview.percentage_1star} />
										</div>
									</div>
								</div>
								{/* All Ratings N Reviews */}
								{/* <RatingsNReviews
									review={review}
									rate={rate}
									ORDER_ID={ORDER_ID}
									getStarValue={getStarValue}
									setReview={setReview}
									re_view={re_view}
								/> */}

							</Col>
						</Row>
					</>

				)
			}
		</GlobalContext.Consumer>
	)
}

export default OrderOnline