//Copyright by Chaafo pvt ltd
//ItemDetails is designed to show item details

import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Image, Badge, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';
import Detailmodal from '../modals/Detailmodal';
import { GlobalContext } from '../../components/GlobalState';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';

function ItemDetail(props) {
    const { MK } = useContext(GlobalContext_);
    var T = localStorage.getItem("tokenn");

    const [quantity, setQuantity] = useState(props.qty || 0);
    const [max] = useState(props.maxValue || 0);
    const [min] = useState(props.minValue || 0);

    const [showDetailmodal, setShowDetailmodal] = useState(false);
    const [price, setPrice] = useState([]);
    const hideDetailmodal = () => setShowDetailmodal(false);
    const { cart, setCart, setTotal, setCount, setEmpty, setPay } = useContext(GlobalContext);

    const { quantities, setQuantities } = useState([{ qty: 0, size_id: 0, item_id: 0, }])

    const sortCart = (data) => {
        data.sort((a, b) => {
            if (a.sort_id > b.sort_id) {
                return 1;
            }
            else if (b.sort_id > a.sort_id) {
                return -1;
            }
            else {
                return 0;
            }

        })
        return data;
    }

    toast.configure();

    //user click to show item details and add something to cart, this function renders the cart on the same time
    //if user is logged in, that means token is there, therefore if case will be executed. Otherwise, else case will be executed.
    async function Loadcart() {

        if (T) {
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "_lang=en");

            var formdata = new FormData();
            formdata.append("token", T);
            formdata.append("device_id", T);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            }
            fetch(urls.LoadCart + "?merchant_keys=" + MK, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 1) {
                        setCart(result.details.data.item);
                        setTotal(result.details.data.total);
                        setCount(result.details.cart_details);
                        setEmpty('');
                        setPay(result.details);
                        return result.details.data.item;  // Return server cart data
                    }
                });
        }
        else {
            const raw = localStorage.getItem('cart');
            const cartFetchedFromLocalStorage = JSON.parse(raw);
            setCart(sortCart(cartFetchedFromLocalStorage));

            const tRaw = localStorage.getItem('total');
            const totalFetchedFromLocalStorage = JSON.parse(tRaw);
            setTotal(totalFetchedFromLocalStorage);

            const cRaw = localStorage.getItem('Count');
            const countFetchedFromLocalStorage = JSON.parse(cRaw);
            setCount(countFetchedFromLocalStorage);

            const eRaw = localStorage.getItem('empty');
            const EmptyFetchedFromLocalStorage = JSON.parse(eRaw);
            setEmpty(EmptyFetchedFromLocalStorage);

            const pRaw = localStorage.getItem('pay');
            const payFetchedFromLocalStorage = JSON.parse(pRaw);
            setPay(payFetchedFromLocalStorage);

            return cartFetchedFromLocalStorage; // Return local cart data
        }
    }

    //function to remove the row of item from cart
    const removecartitem = () => {

        if (T) {
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "_lang=en");
            var formdata = new FormData();
            formdata.append("row", props.index)
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            fetch(urls.RemoveItem + "?merchant_keys=" + MK + "&device_id=" + T + "&token=" + T, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 1) {
                        Loadcart();
                        toast.success('Item removed', {
                            position: "top-center",
                            autoClose: 400
                        });
                        if (cart.length === 1 && Math.abs(props.qty - 1) === 0) {
                            setCart(null);
                            setCount(null);
                        }
                    }
                })
        }
        else {
            // item is present
            const newCart = cart?.filter((item) => {
                return item.item_id !== props.id ? true : item.size_id !== props.size_id;
            });
            localStorage.setItem('cart', JSON.stringify(newCart));
            toast.success('cart updated', {
                position: "top-center",
                autoClose: 400
            });
            Loadcart();
        }
    }

    // funtion to decrease item quantity
    const DecreasecartItem = () => {

        if (quantity > min) {
            setQuantity(quantity - 1);
            props.getValue({ id: props.id, quantity: quantity - 1 });
            
        }
       
        if (T) {
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "_lang=en");
            var formdata = new FormData();
            formdata.append("item_id", props.id);
            formdata.append("price", props.price);
            formdata.append("row", props.index);
            formdata.append("qty", -1)  // why setting -1? is it to remove item at BE?
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            fetch(urls.Add_Delete_Item + "?merchant_keys=" + MK + "&device_id=" + T + "&device_platform=android&transaction_type=pickup&lang=en&_=1644817151280&token=" + T, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 1) {
                        Loadcart();
                        toast.success('Cart Updated', {
                            position: "top-center",
                            autoClose: 400
                        });
                       
                        Loadcart();
                    }
                })
        }
        else {
            const currentItem = cart?.filter((item) => {
                return item.item_id === props.id && item.size_id === props.size_id;
            });
            // item is present
            const newCart = cart?.filter((item) => {
                return item.item_id !== props.id ? true : item.size_id !== props.size_id;
            });
            if (currentItem[0].qty > 0) {
                const itemToAdd = { ...currentItem[0], qty: currentItem[0].qty - 1 };
                newCart.push(itemToAdd);
            }
            localStorage.setItem('cart', JSON.stringify(newCart));
            toast.success('cart updated', {
                position: "top-center",
                autoClose: 400
            });
            Loadcart();
        }
    }

    const addtocart = () => {
        // Increment quantity
        if (quantity < max) {
            setQuantity(quantity + 1);
            props.getValue({ id: props.id, quantity: quantity + 1 });
        }

        if (T) {
            // Prepare headers and form data for the fetch request
            const myHeaders = new Headers();
            myHeaders.append("Cookie", "_lang=en");
            const formdata = new FormData();
            formdata.append("item_id", props.id);
            formdata.append("price", props.price);
            formdata.append("qty", "1");
            formdata.append("size_words", props.size_words);

            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            // Make the fetch request
            fetch(`${urls.Add_Delete_Item}?merchant_keys=${MK}&device_id=${T}&device_platform=web&transaction_type=pickup&lang=en&_=1644817151280&token=${T}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 1) {
                        toast.success(result.msg, {
                            position: "top-center",
                            autoClose: 400
                        });
                        Loadcart();
                    } else {
                        // Handle errors here
                        toast.error('Failed to add item to cart', {
                            position: "top-center",
                            autoClose: 400
                        });
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    toast.error('Error adding item to cart', {
                        position: "top-center",
                        autoClose: 400
                    });
                });
        } else {
            // Handle cart management locally
            const currentItem = cart.find(item => item.item_id === props.id && item.size_id === props.size_id);

            if (!currentItem) {
                // Item not in cart
                const newItemToAdd = {
                    item_id: props.id,
                    size_id: props.size_id,
                    photo: props.image,
                    item_name: props.title,
                    price: props.price,
                    qty: 1,
                    category_id: props.category_id,
                    sort_id: cart.length + 1,
                    cart_count: cart.length,
                    size_words: props.size_words
                };

                cart.push(newItemToAdd);
                localStorage.setItem('cart', JSON.stringify(cart));
                toast.success('Item added to cart', {
                    position: "top-center",
                    autoClose: 400
                });
            } else {
                // Item already in cart
                const newCart = cart.map(item =>
                    item.item_id === props.id && item.size_id === props.size_id
                        ? { ...item, qty: item.qty + 1 }
                        : item
                );

                localStorage.setItem('cart', JSON.stringify(newCart));
                toast.success('Item quantity updated', {
                    position: "top-center",
                    autoClose: 400
                });
            }

            Loadcart();
        }
    };

    //retrieving the total  Qtr. + Half + full 
    const getSizeBasedQuantity = (itemId, size_words) => {
        const cartItem = cart?.find(item =>
            item.item_id == itemId && item?.size_words == size_words)
        const quantityy = cartItem?.qty
        console.log(cart, 'size_words inside function')

        return quantityy;
    };

    const getQuantity = getSizeBasedQuantity(props.id, props.size_words);

    //function to load item details
    useEffect(() => {
        const Itemdetails = () => {

            var myHeaders = new Headers();
            myHeaders.append("Cookie", "_lang=en");

            var formdata = new FormData();
            formdata.append("item_id", props.id);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(urls.ItemDetails + "?merchant_keys=" + MK, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setPrice(result.details.data.prices)
                })
            localStorage.setItem("id", props.id);
        }
        Itemdetails();
    }, [MK])

    return (
        <div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm mb-2">

            <div className="list-card-body p-3 d-flex justify-content-between align-items-center">
                {props.size_words ? (
                    <>
                        <div className="text-gray mb-0">
                            {(props.discountprice > 0) ? (<p>
                                <del>{props.priceUnit}{props.price} </del>{props.priceUnit}{props.discountprice} </p>)
                                :
                                <p className="text-gray time mb-0"><b>{props.size_words}</b><span>{' '}</span>{props.priceUnit}{props.price}</p>
                            }
                        </div>
                        <div>
                            {
                                (props.price) && getQuantity > 0 ?
                                    <span className="count-number float-right">
                                        <Button variant="outline-secondary" onClick={DecreasecartItem} className="btn-sm left dec"> <Icofont icon="minus" /> </Button>
                                        <input className="count-number-input p-0" type="text" value={getQuantity} readOnly />
                                        <Button variant="outline-secondary " onClick={addtocart} className="btn-sm right inc" > <Icofont icon="icofont-plus" /> </Button>
                                    </span>
                                    :
                                    <Button variant='outline-secondary' onClick={addtocart} size="sm">ADD</Button>
                            }
                        </div>
                    </>
                )
                    : ''
                }
            </div>
        </div>
    );
}

export default ItemDetail;

