//Copyright by Chaafo pvt ltd

import React, { Component, useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Media, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import StarRating from '../common/StarRating';
import Icofont from 'react-icofont';
import EditReviewModal from '../modals/EditReviewModal';
import { MERCHANTKEY } from '../../App';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';

function Review(props) {

   const [showEditProfile, setShow] = useState(false);
   const hideEditProfile = () => setShow(false);
   var T = localStorage.getItem("tokenn");

   const {MK} = useContext(GlobalContext_);
   const [review, setREview] = useState([]);
   const history = useHistory();

   const stars= props.id + props.ratingStars

   //Function to store review id
   const passid = () => {
      localStorage.setItem("rev_id", props.id);
   }

   //function to load reviews 
   const loadreviews = async () => {
      const response3 = await fetch(urls.LoadReview + '?limit=10&merchant_keys=' + MK + '&device_id=' + T + '&device_platform=web&lang=en&_=1644299640559&token=' + T);
      const data4 = await response3.json();
      setREview(data4.details.data);
   }

   //Function to load reviews in realtime
   useEffect(() => {
      const loadreviews = async () => {
         const response3 = await fetch(urls.LoadReview + '?limit=10&merchant_keys=' + MK + '&device_id=' + T + '&device_platform=web&lang=en&_=1644299640559&token=' + T);
         const data4 = await response3.json();
         setREview(data4.details.data);
      }
      loadreviews();
   }, [MK])

   //Function to count no. of reviews
   const CountReview = () => {
      fetch(urls.CountReview + "?merchant_keys=" + MK)

   }

   //Function to delete review
   const deletereview = () => {
      fetch(urls.DeleteReview + "?id=" + props.id + "&merchant_keys=" + MK + "&device_id=" + T + "&device_platform=web&token=" + T + "&lang=en&_=1654085374186")
         .then(response => response.json())
         .then(result => {
            if (result.code == 1) {
               loadreviews();
               CountReview();
               history.push('/');
            }
         })
   }

   return (
      <div className="reviews-members pt-4 pb-4">
         <EditReviewModal show={showEditProfile} onHide={hideEditProfile} />
         <Media>
            <Link to="#"><Image alt={props.imageAlt} src={props.image} className="mr-3 rounded-pill" /></Link>
            <Media.Body>
               <div className="reviews-members-header">
                  <div className="star-rating float-right">
                     <StarRating 
                     fontSize={14} disabled={true} star={props.ratingStars} getValue={() => { }} />
                  </div>

                  <h6 className="mb-1"><Link className="text-black" to={props.profileLink}>{props.name}</Link></h6>
                  <p className="text-gray">{props.reviewDate}</p>
               </div>
               <div className="reviews-members-body">
                  <p>{props.reviewText}

                     {props.can_edit == true ?
                        <div className="float-right">

                           <Link className="text-primary mr-2" to="#" onClick={() => setShow(true)} onClickCapture={passid}><Icofont icon="ui-edit" /> Edit</Link>
                           <Link className="text-danger " to="#" onClick={passid} onClickCapture={deletereview}><Icofont icon="ui-delete" /> Delete</Link>

                        </div> : ''}
                  </p>
               </div>
            </Media.Body>
         </Media>
      </div>
   );
}

Review.propTypes = {
   id: PropTypes.string.isRequired,
   image: PropTypes.string.isRequired,
   ImageAlt: PropTypes.string,
   ratingStars: PropTypes.number.isRequired,
   name: PropTypes.string.isRequired,
   profileLink: PropTypes.string.isRequired,
   reviewDate: PropTypes.string.isRequired,
   reviewText: PropTypes.string.isRequired,
   likes: PropTypes.string.isRequired,
   dislikes: PropTypes.string.isRequired,
   can_edit: PropTypes.bool.isRequired,
   otherUsers: PropTypes.arrayOf(
      PropTypes.shape({
         name: PropTypes.string.isRequired,
         image: PropTypes.string.isRequired,
         url: PropTypes.string.isRequired
      })
   )
};

export default Review;