//Copyright by Chaafo pvt ltd

import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Image, Badge, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';
import { urls } from '../URLs';
import { GlobalContext } from '../../components/GlobalState';

import Detailmodal from '../modals/Detailmodal';

const Merchantkifile = (props) => {

	
	var MK = sessionStorage.getItem("MK");
	var T = localStorage.getItem("tokenn");
	const history = useHistory('');
	const { cart, setCart, setTotal, setCount, setEmpty, Pay, setPay, address, setAddress } = useContext(GlobalContext);

	async function Loadcart() {
		var myHeaders = new Headers();
		myHeaders.append("Cookie", "_lang=en");
		var formdata = new FormData();
		formdata.append("device_id", T);
		formdata.append("token", T);


		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: formdata,
			redirect: 'follow'
		};

		fetch(urls.LoadCart+"?merchant_keys=" + MK + "&token=" + T, requestOptions)
			.then(response => response.json())
			.then(result => {
				if (result.code === 1) {
					setCart(result.details.data.item);
					setTotal(result.details.data.total);
					setCount(result.details.cart_details);
					setEmpty('');
					setPay(result.details);
				}
			})
	}

	const clearCart = () => {

		var myHeaders = new Headers();
		myHeaders.append("Cookie", "_lang=en");

		var formdata = new FormData();
		formdata.append("token", T);
		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: formdata,
			redirect: 'follow'
		};

		fetch(urls.ClearCart+"?merchant_keys=" + MK, requestOptions)
			.then(response => response.json())
			.then(result => {
				if (result.code === 1) {

					setCart(null);
					setCount(null);
					Loadcart();

				}
			})
	}


	const passkey = () => {

		clearCart();
		Loadcart();
		localStorage.removeItem("cart");
		localStorage.removeItem("ORDER_ID");
		sessionStorage.setItem("MK", props.mykey);
		history.push('/');
		window.location.reload('/');
	}

	
	return (

		<div className="list-card bg-white  rounded overflow-hidden position-relative shadow-sm">
			{/* <Detailmodal show={showDetailmodal} onHide={hideDetailmodal} /> */}
			<div className="list-card-image">
				{props.rating ? (
					<div className="star position-absolute">
						<Badge variant="success">
							<Icofont icon='star' /> {props.rating}
						</Badge>
					</div>
				)
					: ""
				}

				<Link >
					<Image src={props.image} className={props.imageClass} onClick={passkey} alt={props.imageAlt} />
				</Link>
			</div>

			<div className="p-3 position-relative">
				<div className="list-card-body">
					<h6 className="mb-1"><Link className="text-black">{props.title}</Link></h6>

					{props.subTitle ? (
						<p className="text-gray mb-3">{props.subTitle}</p>
					)
						: ''
					}


					{(props.time) ? (
						<p className="text-gray mb-3 time">
							{props.time ? (
								<span className="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2">
									<Icofont icon='wall-clock' /> {props.time}
								</span>
							)
								: ""
							}

						</p>
					) : ''
					}

				</div>

				{props.offerText ? (
					<div className="list-card-badge">
						<Badge variant={props.offerColor}>OFFER</Badge> <small>{props.offerText}</small>
					</div>
				)
					: ""
				}
			</div>
		</div>
	);
}

Merchantkifile.propTypes = {

	id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	imageAlt: PropTypes.string,
	image: PropTypes.string.isRequired,
	imageClass: PropTypes.string,
	offerText: PropTypes.string,
	offerColor: PropTypes.string,
	subTitle: PropTypes.string,
	time: PropTypes.string,
	mykey: PropTypes.string.isRequired,

};
Merchantkifile.defaultProps = {
	imageAlt: '',
	imageClass: '',
	offerText: '',
	offerColor: 'success',
	subTitle: '',
	time: '',
	rating: '',
}

export default Merchantkifile;