//Copyright by Chaafo pvt ltd


import React, { useState, useContext } from "react";
import { Link, useHistory } from 'react-router-dom';
import OTPInput, { ResendOTP } from "otp-input-react";
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { urls } from "./URLs";
import GlobalContext_ from "../GlobalContext";

import { Helmet } from 'react-helmet';

function OTP() {
    var keycheck = sessionStorage.getItem("keycheck");
    const {MK} = useContext(GlobalContext_);
    const [OTP, setOTP] = useState("");
    const history = useHistory();

    var T = localStorage.getItem("tokenn");

    async function Otp() {

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en");

        var formdata = new FormData();
        formdata.append("code", OTP);
        formdata.append("token", keycheck);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        //This api verifies the OTP
        let result = await fetch(urls.MobileVerification+"?merchant_keys=" + MK, requestOptions, {

        });
        result = await result.json();

        if (result.code == 1) {
            localStorage.setItem("tokenn", keycheck);
            history.push("/")
            alert(result.msg);
        }
        else {
            alert(result.msg);
        }
    }

    var resendotp=0;
    var first_name = localStorage.getItem("first_name");
    var contact_phone = localStorage.getItem("contact_phone");
    var email_address = localStorage.getItem("email_address");

    async function customer_register_mobile() {

        //api will be called only 3 times i.e. resend otp button will work only three times.
        if(resendotp == 3){
            localStorage.removeItem("first_name");
            localStorage.removeItem("contact_phone");
            localStorage.removeItem("email_address");
            toast.error("You have reached the maximum limit", {
                position: "top-center",
                autoClose: 600
            });
        }
        else{
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en");

        var formdata = new FormData();
        formdata.append("first_name", first_name);
        formdata.append("contact_phone", contact_phone);
        formdata.append("email_address", email_address);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        //RegisterMobile api is called to send otp to the user
        let result = await fetch(urls.RegisterMobile+"?next_step2=firsttime&contact_phone=" + contact_phone + "&merchant_keys=" + MK, requestOptions, {

        });

        result = await result.json();
        sessionStorage.setItem("keycheck", result.details.token);

        if (result.code === 1) {
            //if otp is sent, resendotp value will be increased by 1. 
             resendotp = resendotp+1;
        }
        else {
            toast.error(result.msg, {
                position: "top-center",
                autoClose: 600
            });     
        }
    }
    }

    return (
        <>
         <Helmet>
         <meta name="robots" content="noindex, nofollow" />
         </Helmet>
            <div className="row">
            <div className="col text-center">
                <h5 className="mt-4 mb-3">Enter the OTP sent to you</h5>
                <OTPInput
                    style={{ display: 'dflex' }}
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                />
                <div className="align-items-center">
                    <Button variant="info" className="font-weight-bold mb-2 mt-3" onClick={Otp} >Verify OTP</Button>     
                    <Button variant="info" className="font-weight-bold mb-2 mt-3 ml-1" onClick={customer_register_mobile}>Resend</Button>
                </div>
            </div>
        </div>
        </>

       
    );
};

export default OTP