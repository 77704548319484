//Copyright by Chaafo pvt ltd
//ItemsCarousel is designed to show categories in floating carousel

import React, { useState, useEffect, useContext } from 'react';
import OwlCarousel from 'react-owl-carousel3';
import MayLikeItem from './MayLikeItem';
import { CAT_N1 } from '../../App';
import GlobalContext from '../../GlobalContext';

function ItemsCarousel() {
  
  //Catories context is used here
  const {cat_n1} = useContext(GlobalContext);

  return (
    <OwlCarousel  nav loop {...options} className="owl-theme owl-carousel-five offers-interested-carousel ">
      {(cat_n1?.map((cat_n1) =>
        <div className="item popular">
          <MayLikeItem
            title={cat_n1.category_name}
            image={cat_n1.photo_url}
            imageClass='img-fluid'
            imageAlt={cat_n1.photo_url}
            // category_id={1}
          />
        </div>
      ))}

    </OwlCarousel>
  );
}


const options = {
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
    1200: {
      items: 5,
    },
  },
  lazyLoad: true,
  pagination: "false",
  loop: true,
  dots: false,
  nav: true,
  autoplay: true,
  autoplaySpeed: 1000,
  autoplayTimeout: 2000,
  autoplayHoverPause: true,

  navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"]

}

export default ItemsCarousel;