//Copyright by Chaafo pvt ltd

import React,{useState} from 'react';
import PropTypes from 'prop-types'; 
import Icofont from 'react-icofont';
function CartDropdownItem (props){
	
		return (
			<p className="mb-2"> 
             	<Icofont icon={props.icoIcon} className={"mr-1 " + props.iconClass}/> 
             	<span>{props.title} X {props.qty}  </span>
             	<span className="float-right text-secondary">₹ {props.price * props.qty}</span>
            </p>

		);
	}


CartDropdownItem.propTypes = {
  id:PropTypes.number.isRequired,
  qty:PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  icoIcon: PropTypes.string.isRequired,
  iconclass: PropTypes.string.isRequired
};

CartDropdownItem.defaultProps = {
  id:'',
  title: '',
  price: '',
  icoIcon: '',
  iconclass: '',
}

export default CartDropdownItem;