import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { GlobalContext } from '../GlobalState';

import GlobalContext_ from '../../GlobalContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { urls } from '../URLs';

function BookATable({ Name, setName, Email, setEmail, GetDate, SETDATE, GetTime, SETTIME, Time, Date, DATE, TIME, T }) {
    const [Mobile, setMobile] = useState('');
    const [N_Guest, setN_Guest] = useState('');
    const { MK } = useContext(GlobalContext_)

    const SaveBooking = () => {

        var Guest = Math.abs(N_Guest);
        fetch(urls.BookingTable + '?number_guest=' + Guest + '&date_booking=' + DATE + '&booking_time=' + TIME + '&booking_name=' + Name + '&email=' + Email + '&mobile=' + Mobile + '&booking_notes=hi&merchant_keys=' + MK + '&device_id=' + T + '&token=' + T)
            .then(response => response.json())
            .then(result => {
                if (result.code == 1) {
                    toast.success(result.msg, {
                        position: "top-center",
                        autoClose: 400
                    });
                }
                else {
                    toast.error(result.msg, {
                        position: "top-center",
                        autoClose: 400
                    });
                }
            })

    }

    const handleNameChange = (e) => {
        const { value } = e.target;
        if (/^[a-zA-Z\s]*$/.test(value)) {
            setName(value);
        }
    };

    const handleMobileChange = (e) => {
        const { value } = e.target;
        if (/^\d*$/.test(value)) {
          setMobile(value);
        }
      };

      const handleGuestChange = (e) => {
        const { value } = e.target;
        if (/^\d*$/.test(value) && Number(value) > 0) {
          setN_Guest(value);
        }
      };
    
      const preventNonNumericInput = (e) => {
        if (e.key.length === 1 && !/^[0-9]$/.test(e.key)) {
          e.preventDefault();
        }
      };

    return (
        <div id="book-a-table" className="bg-white rounded p-4 mb-5 rating-review-select-page" style={{boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'}}>
            <h5 className="mb-4">Book A Table</h5>
            <Form>
                <Row>
                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Full Name"
                                onChange={handleNameChange}
                                value={Name}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="email" placeholder="Enter Email address" onChange={(e) => setEmail(e.target.value)} value={Email} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label>Date</Form.Label>
                            <Form.Control as="select" onClick={GetDate} onChange={(e) => SETDATE(e.target.value)}>
                                <option>Enter Date</option>

                                {(Date?.map((date) =>
                                    <option value={date}>{date}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label>Time</Form.Label>
                            <Form.Control as="select" onClick={GetTime} onChange={(e) => SETTIME(e.target.value)}>
                                <option>Enter Time</option>
                                {(Time?.map(time =>
                                    <option value={time} >{time}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                <Col sm={6}>
            <Form.Group>
              <Form.Label>Mobile number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter mobile number"
                value={Mobile}
                onChange={handleMobileChange}
                pattern="\d*"
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group>
              <Form.Label>Number of Guests</Form.Label>
              <Form.Control
                type="number"
                min="1"
                placeholder="Enter number of guests"
                value={N_Guest}
                onChange={handleGuestChange}
                onKeyDown={preventNonNumericInput}
              />
            </Form.Group>
          </Col>
                </Row>
                <Form.Group className="text-right">
                    <Button variant="primary" type="button" onClick={SaveBooking}> Submit </Button>
                </Form.Group>
            </Form>
        </div>
    )
}

export default BookATable;
