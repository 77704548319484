//Copyright by Chaafo pvt ltd


import React from 'react';
import {Link} from 'react-router-dom';
import {Row,Col,Container,Image} from 'react-bootstrap';

import { Helmet } from 'react-helmet';

function NotFound (){

    	return (
		<>
		 <Helmet>
		 <meta name="robots" content="noindex, nofollow" />
		  </Helmet>
			<section className="section pt-5 pb-5 osahan-not-found-page">
		         <Container>
		            <Row>
		               <Col md={12} className="text-center pt-5 pb-5">
		                  <Image alt="404" className="img-fluid" src="/img/404.png"  />
		                  <h1 className="mt-2 mb-2">Page not found</h1>
		                  <p>Uh-oh! Looks like the page you are trying to access, doesn't <br />exist. Please start afresh.</p>
		                  <Link className="btn btn-primary btn-lg" to="/">GO HOME</Link>
		               </Col>
		            </Row>
		         </Container>
		    </section>
			</>
    		
    	);
    }



export default NotFound;