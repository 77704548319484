import React, { useState, useContext , useEffect} from 'react';
import { Link } from 'react-router-dom';

import { Form, Button } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import Review from '../common/Review';
import StarRating from '../common/StarRating';
import { urls } from '../URLs';
import { toast } from 'react-toastify';
import GlobalContext_ from '../../GlobalContext';

import { Helmet } from 'react-helmet';

const RatingsNReviews = ({ review, ORDER_ID, getStarValue, setReview, re_view, setCountreview, setREview, Loadcart, rate_f }) => {
    
	const { MK } = useContext(GlobalContext_);
	var T = localStorage.getItem("tokenn");

	const [feedbackStatus, setFeedbackStatus]= useState(false);
    const [topRatedFirst, setTopRatedFirst] = useState(false);

    //Function to add ratings and review
	const rate = () => {
        setFeedbackStatus(!feedbackStatus);
		fetch(urls.AddReview + "?merchant_keys=" + MK + "&token=" + T + "&review_order_id=" + ORDER_ID + "&review=" + re_view + "&rating=" + rate_f)
			.then(response => response.json())
			.then(result => {
				if (result.code == 1) {
					toast.success(result.msg, {
						position: "top-center",
						autoClose: 400
					});
					loadreviews();
					CountReview();
					setReview('');
				}
				else {
					toast.error(result.msg, {

						position: "top-center",
						autoClose: 400
					});
					setReview('');
				}
			})
	}

    //Function to load reviews
	const loadreviews = async () => {
		const response3 = await fetch(urls.LoadReview + '?limit=10&merchant_keys=' + MK + '&device_id=' + T + '&device_platform=web&lang=en&_=1644299640559&token=' + T);
		const data4 = await response3.json();
		setREview(data4.details.data);
	}

    //Function to count the total reviews
	const CountReview = () => {
		fetch(urls.CountReview + "?merchant_keys=" + MK)
			.then(response => response.json())
			.then(result => {
				if (result.code == 1) {
					setCountreview(result.details);
				}
			})
	}

	//Function to fetch the total no. of reviews in realtime
	useEffect(() => {
		const CountReview = () => {
			fetch(urls.CountReview + "?merchant_keys=" + MK)
				.then(response => response.json())
				.then(result => {
					if (result.code == 1) {
						setCountreview(result.details);
					}
				})
		}
		Loadcart();
		CountReview();
		loadreviews();
	}, [MK])

    const handleTopRatedClick = () => {
        setTopRatedFirst(!topRatedFirst);
    }

    const topRatedReview = (Review) => {
        return Review?.slice().sort((a, b) => b?.rating - a?.rating);
    }

    const sortedReview = topRatedFirst ? topRatedReview(review) : review;

    return (
        <>
         <Helmet>
         <meta name="robots" content="noindex, nofollow" />
           </Helmet>
            <div className="bg-white rounded p-4 mb-4 restaurant-detailed-ratings-and-reviews" style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
                <Link to="#" className="btn btn-outline-primary btn-sm float-right" onClick={handleTopRatedClick}>{!topRatedFirst ? 'Top Rated' : 'Latest'} </Link>
                <h5 className="mb-4">All Ratings and Reviews</h5>
                <hr style={{ background: 'lightgrey' }} />
                {(sortedReview?.map((ratings, index) =>
                    <Review
                        id={index}
                        image={ratings.avatar}
                        ImageAlt=""
                        ratingStars={ratings.rating}
                        Name={ratings.client_name}
                        profileLink="#"
                        reviewDate={ratings.date}
                        reviewText={ratings.review}
                    />
                ))}

            </div>
            {ORDER_ID ?
               
               (!feedbackStatus?
               <div className="bg-white rounded p-4 mb-5 rating-review-select-page" style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }} >
                    <h5 className="mb-4">Your Feedback is valuable</h5>
                    <p className="mb-2">Help Us Improve Our Service For You</p>
                    <div className="mb-4">
                        <div className="star-rating">
                            <StarRating fontSize={26} star={5} getValue={getStarValue} />
                        </div>
                    </div>
                    <Form>
                        <Form.Group>
                            <Form.Label>Share more about your experience</Form.Label>
                            <Form.Control as="textarea" value={re_view} onChange={(e) => setReview(e.target.value)} />
                        </Form.Group>
                        <Form.Group >
                            <Button variant="primary" size="sm" type="button" onClick={rate}
                            > Submit Comment </Button>
                        </Form.Group>
                    </Form>
                </div> :
                <p className='m-4 p-4 justify-content-center d-flex'>
                    Thanks for your feedback!
                </p>
                
            )
                : 
                " "}
        </>
    )
}

export default RatingsNReviews;