//Copyright by Chaafo pvt ltd
//Set Delivery modal is designed to set the address directly in cart.  

import React, { useState, useEffect, useContext } from 'react';
import { Form, InputGroup, Modal, ButtonToolbar, Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import Icofont from 'react-icofont';
import { GlobalContext } from '../../components/GlobalState';
import { MERCHANTKEY } from '../../App';
import { toast } from 'react-toastify';
import { urls } from '../URLs';
import 'react-toastify/dist/ReactToastify.css';
import GlobalContext_ from '../../GlobalContext';

function SetDelivery(props) {
	var myHeaders = new Headers();
	const {MK} = useContext(GlobalContext_);
	var T = localStorage.getItem("tokenn");
	myHeaders.append("Cookie", "_lang=en");

	var formdata = new FormData();
	formdata.append("token", T);

	var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: formdata,
		redirect: 'follow'
	};

	toast.configure();

	const initialValues = { street: "", city: "", state: "", zipcode: "", location_name: "", country_code: "" };
	const [formValues, setFormValues] = useState(initialValues);
	const [formErrors, setFormErrors] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const [statelist, setStateList] = useState([]);
	const [citylist, setCityList] = useState([]);
	const [show1, setHide1] = useState('');
	const [stateid, setStateid] = useState(1);
	const { address, setAddress } = useContext(GlobalContext);
	const { cart, setCart, setTotal, setCount, setEmpty, setPay } = useContext(GlobalContext);

	//Function to load the address in cart in realtime  
	async function Loadcart() {
		fetch(urls.LoadCart+"?merchant_keys=" + MK + "&token=" + T, requestOptions)
			.then(response => response.json())
			.then(result => {
				if (result.code === 1) {
					setCart(result.details.data.item);
					setTotal(result.details.data.total);
					setCount(result.details.cart_details);
					setEmpty('');
					setPay(result.details);
				}
			})
	}

	var del_id = localStorage.getItem("del_id");

	//Function to edit the delivery address set on cart
	useEffect(() => {
		const Edit_Address = () => {
			fetch(urls.EditAddress+"?merchant_keys=" + MK + "&token=" + T + "&device_id=" + T + "&id=" + del_id)
				.then(response => response.json())
				.then(result => {
					setFormValues(result.details)
				})
		}
		Edit_Address();
	}, [props.show,MK])

	//Function to save address in address booklist
	async function AddAddress() {

		var myHeaders = new Headers();
		myHeaders.append("Cookie", "_lang=en")
		var formdata = new FormData();
		formdata.append("street", formValues.street);
		formdata.append("city", formValues.city);
		formdata.append("state", formValues.state);
		formdata.append("zipcode", formValues.zipcode);
		formdata.append("location_name", formValues.location_name);
		formdata.append("country_code", "IN");

		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: formdata,
			redirect: 'follow'
		};

		fetch(urls.SaveAddress+"?merchant_keys=" + MK + "&token=" + T, requestOptions)
			.then(response => response.json())
			.then(result => {
				if (result.code === 1) {
					submitontrue();
					fetch(urls.AddressList+'?merchant_keys=' + MK + '&token=' + T)
						.then(response => response.json())
						.then(result => {
							if (result.code == 1) {
								setAddress(result.details.data);

								fetch(urls.SetAddress+"?address_id=" + result.details.data[0].id + "&street=" + formValues.street + "&city=" + formValues.city + "&state=" + formValues.state + "&zipcode=" + formValues.zipcode + "&country_code=IN&location_name=" + formValues.location_name + "&merchant_keys=" + MK + "&device_id=" + T + "&transaction_type=delivery&lang=en&_=1647244059585")
									.then(response => response.json())
									.then(result => {
										if (result.code === 1) {
											Loadcart();
										}
										toast.success(result.msg, {
											position: "top-center",
											autoClose: 400
										});
									});
							}
						})
				}
				else {
					alert("Address not saved");
				}
			})
	}

	 //Function to handle changes in fields of set delivery modal while editing
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });
	};

	// Function to handle errors while submitting the address to get saved
	const handleSubmit = (e) => {
		e.preventDefault();
		setFormErrors(validate(formValues));
		setIsSubmit(true);

	};

	useEffect(() => {
		if (Object.keys(formErrors).length === 0 && isSubmit) {

			AddAddress();
		}
	}, [formErrors]);

	//Function to validate errors
	const validate = (values) => {
		const errors = {};

		if (!values.street) {
			errors.street = "*street is required!";
		}
		if (!values.city) {
			errors.city = "*city is required!";
		}
		if (!values.state) {
			errors.state = "*state is required!";
		}
		if (!values.zipcode) {
			errors.zipcode = "*zipcode is required!";
		}
		if (!values.location_name) {
			errors.location_name = "*location is required";
		}
		return errors;
	};

	//Function to show state list while editing address
	const Statelist = () => {

		fetch(urls.StateList+'?merchant_keys=' + MK)
			.then(response => response.json())
			.then(result => {
				if (result.code == 1) {
					setStateList(result.details);

				}
			})
	}


    const submitontrue = () => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            setHide1(props.onHide);
            return props.onHide
        }
    }

	const onChangeHandler = (e) => {
		const index = e.target.selectedIndex;
		const el = e.target.childNodes[index]
		setStateid(el.getAttribute('id'))
	}

	//Function to show City list according to the state chosen
	const Citylist = () => {

		if (stateid) {
			var myHeaders = new Headers();
			myHeaders.append("Cookie", "_lang=en");

			var formdata = new FormData();
			formdata.append("state_id", stateid);

			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			};

			fetch(urls.CityList+"?merchant_keys=" + MK, requestOptions)
				.then(response => response.json())
				.then(result => {
					if (result.code == 1) {
						setCityList(result.details);
					}
				})
		}
	}


	return (

		<Modal
			show={props.show}
			onHide={props.onHide}
			centered
		>
			<Modal.Header closeButton={true}>
				<Modal.Title as='h5' id="add-address">Add Delivery Address</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<form>
					<div className="form-row" >
						<Form.Group className="col-md-12">
							<Form.Label>STREET</Form.Label>
							<InputGroup>
								<Form.Control type="text" placeholder="Enter house number, street name, landmark" name="street" value={formValues.street} onChange={handleChange} id="street" />
							</InputGroup>
						</Form.Group>
						<p className='Val'>{formErrors.street}</p>
						<Form.Group className="col-md-12">
							<Form.Label>STATE</Form.Label>
							<Form.Control as="select" onInput={onChangeHandler} onClick={Statelist} type="text" placeholder="Enter your State" name="state" value={formValues.state} onChange={handleChange}  >
								<option>Enter your State</option>
								{(statelist?.map((st) =>
									<option id={st.state_id} >{st.name}</option>
								))}
							</Form.Control>
						</Form.Group>
						<p className='Val'>{formErrors.state}</p>

						<Form.Group className="col-md-12">
							<Form.Label>CITY</Form.Label>
							<Form.Control as="select" onClick={Citylist} type="text" placeholder="Enter your City" name="city" value={formValues.city} onChange={handleChange} id="city" >
								<option>Enter your City</option>
								{(citylist?.map((ct) =>
									<option id={ct.city_id}>{ct.name}</option>
								))}
							</Form.Control>
						</Form.Group>
						<p className='Val'>{formErrors.city}</p>
						<Form.Group className="col-md-12">
							<Form.Label>ZIPCODE</Form.Label>
							<Form.Control type="number" placeholder="Enter your Zipcode" name="zipcode" value={formValues.zipcode} onChange={handleChange} id="zipcode" />
						</Form.Group>
						<p className='Val'>{formErrors.zipcode}</p>
						<Form.Group className="col-md-12">
							<Form.Label>LOCATION NAME</Form.Label>
							<Form.Control type="text" placeholder="Enter your Location" name="location_name" value={formValues.location_name} onChange={handleChange} id="location_name" />
						</Form.Group>
						<p className='Val'>{formErrors.location_name}</p>
					</div>
				</form>
			</Modal.Body>

			<Modal.Footer>
				<Button type='button' variant="outline-primary" onClick={props.onHide} className="d-flex w text-center justify-content-center">CANCEL</Button>
				<Button type='button' variant="primary" className='d-flex w text-center justify-content-center' onClick={handleSubmit}>SUBMIT</Button>
			</Modal.Footer>
		</Modal>
	);
}


export default SetDelivery;