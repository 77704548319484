//Copyright by Chaafo pvt ltd
//Gallery Carousel stores and shows all the images of gallery of the restaurant

import React, { useState, useContext } from 'react';
import OwlCarousel from 'react-owl-carousel3';
import { Image } from 'react-bootstrap';
import GlobalContext_ from '../../GlobalContext';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


var T = localStorage.getItem("tokenn");
const GalleryCarousel=(props)=>{
 
  const {MK,gallery} = useContext(GlobalContext_);
  const [itemscount] = useState(0)
  const [showing] = useState(0)

  const options = {
    responsive: {
      0: {
        items: 2,
      },
      764: {
        items: 2,
      },
      765: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
    lazyLoad: true,
    loop: true,
    autoplay: true,
    autoplaySpeed: 1000,
    dots: false,
    autoplayTimeout: 2000,
    nav: true,
    navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
    autoplayHoverPause: true,
  }

  return (
    <div>
      <OwlCarousel nav loop {...options} className="owl-theme homepage-ad ">
        
        {(gallery?.map((Gallery, index) =>
          <div className="item" key={index}>
            <Image fluid src={Gallery} />
          </div>
        ))} 
      </OwlCarousel>
    </div>
  );
}

export default GalleryCarousel;