//Copyright by Chaafo pvt ltd
//Invoice page contains the information about invoice, print invoice button, and billing details

import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Image, Table } from 'react-bootstrap';
import Icofont from 'react-icofont';
import HeadingValue from './common/HeadingValue';
import PageTitle from './common/PageTitle';
import { urls } from './URLs';
import GlobalContext_ from '../GlobalContext';

function Invoice() {

	const {MK, restaurantinfo} = useContext(GlobalContext_);
	var T = localStorage.getItem("tokenn");
	var ORDER_ID = localStorage.getItem("ORDER_ID");
	const [Customerdetails, setCustomerdetails] = useState([]);
	const [OrderDetails1, setOrderDetails1] = useState([]);
	const [Merchantdetails, setMerchantdetails] = useState([]);
	const [OrderDetails3, setOrderDetails3] = useState('');
	const [logo, setlogo] = useState('');

	// to fetch order details for generating invoice
	useEffect(() => {
		async function invoice() {
			var myHeaders = new Headers();
			myHeaders.append("Cookie", "_lang=en");

			var formdata = new FormData();
			formdata.append("token", T);
			formdata.append("id", ORDER_ID);

			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			};

			fetch(urls.Invoice + "?merchant_keys=" + MK, requestOptions)
				.then(response => response.json())
				.then(result1 => (setCustomerdetails(result1.details.customer_details), setOrderDetails1(result1.details.htmlarray), setMerchantdetails(result1.details.merchant_details), setOrderDetails3(result1.details.cartamount), setlogo(result1.details.merchant_logo))
				)
		}
		invoice();
	},[MK]);

	//Function to print invoice
	const printpdf = () => {
		window.print(80, 100);
	}

	return (
		<>
		
            
			{ORDER_ID ?
				<div>
					<PageTitle
						title="Invoice"
						subTitle={ORDER_ID}
					/>
					<div>
						<Container>
							<Row>
								<Col md={8} className="mx-auto">
									<div className="p-5 osahan-invoice bg-white shadow-sm">
										<Row className="mb-5 pb-3">
											<Col md={8} xs={10}>
												<h4 className="mt-0">Thanks for choosing <strong className="text-secondary">chAafo</strong>, User ! Here are your order details: </h4>
											</Col>
											<Col md={4} xs={2} className="pl-0 text-right">
												
												<Image alt="logo" src={logo} />
											</Col>
										</Row>

										<Row>
											{(Customerdetails?.map(Y_orders =>

												<Col md={6}>
													<HeadingValue
														heading={Y_orders.label}
														value={Y_orders.value}
														className="text-black ml-2"
													/>
												</Col>
											))}
										</Row>

										<Row className="mt-2">
											<Col md={12}>
												<HeadingValue className='font-weight-bold'
													heading='Ordered from:'
												/>
												{(Merchantdetails?.map(M_orders =>

													<Col md={6}>
														<HeadingValue
															heading={M_orders.label}
															value={M_orders.value}
															className="text-black"
														/>
													</Col>
												))}
												<table className='table'>
													<thead className="thead-light">
														<tr>
															<th className="text-black font-weight-bold" scope="col">Item Name</th>
															<th className="text-black font-weight-bold" scope="col">Quantity</th>
															<th className="text-black font-weight-bold" scope="col">Price</th>
															<th className="text-black font-weight-bold" scope="col">Total Price</th>
														</tr>
													</thead>

													<tbody>
														{(OrderDetails1?.map(order =>
															<tr>
																<td>{order.item_name}</td>
																<td>{order.qty}</td>
																<td>{order.price - order.discount}</td>
																<td>{order.item_total_price - order.discount}</td>
															</tr>
														))}

													</tbody>
												</table>


												<div className="card-body">
													<div className="d-sm-flex justify-content-center justify-content-sm-between">

														<table className='table'>


															<tr>Less Voucher :₹ {OrderDetails3.less_voucher}</tr>
															<tr>Subtotal : {OrderDetails3.cart_total}</tr>
															<tr>CGST : {OrderDetails3.CGST_percent}</tr>
															<tr>CGST Amount: ₹ {OrderDetails3.CGST_value}</tr>
															<tr>SGST : {OrderDetails3.SGST_percent}</tr>
															<tr>SGST Amount: ₹ {OrderDetails3.SGST_value}</tr>
															<tr>Delivery Charges: ₹ {OrderDetails3.delivery_charges}</tr>
															<tr>Total : {OrderDetails3.total}</tr>

															<button className='center' style={{ marginLeft: '260px' }} onClick={printpdf}>PRINT</button>




														</table>
													</div>
												</div>

											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</div>
				: <div></div>}
		</>
	);
}

export default Invoice;