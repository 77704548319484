//Copyright by Chaafo pvt ltd
//Edit Review Modal appears when user wants to review his/her review


import React, { useState, useContext, useEffect } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { MERCHANTKEY } from '../../App';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { urls } from '../URLs';
import 'react-toastify/dist/ReactToastify.css';
import GlobalContext_ from '../../GlobalContext';

function EditReviewModal(props) {
    var rev_id = localStorage.getItem("rev_id");
    const {MK} = useContext(GlobalContext_);
    var T = localStorage.getItem("tokenn");
    const [countreview, setCountreview] = useState(0);
    const [reviewToEdit, setReviewToEdit] = useState('');
    const [newreview, setNewReview] = useState('');

    toast.configure();

    //function to load reviews 
    const loadreviews = async () => {
        const response3 = await fetch(urls.LoadReview + '?limit=10&merchant_keys=' + MK + '&device_id=' + T + '&device_platform=web&lang=en&_=1644299640559');
        const data4 = await response3.json();

    }

    //Function to count no. of reviews
    const CountReview = () => {
        fetch(urls.CountReview + "?merchant_keys=" + MK)
            .then(response => response.json())
            .then(result => {
                if (result.code == 1) {
                    setCountreview(result.details);

                }
            })
    }

    //Function to get review in edit review modal using review id
    useEffect(() => {
        const GetReview = () => {
            fetch(urls.GetReview + "?merchant_keys=" + MK + "&id=" + rev_id + "&token=" + T + "&device_id=" + T)
                .then(response => response.json())
                .then(result => {
                    if (result.code == 1) {
                        setReviewToEdit(result.details.data);
                    }
                })
        }
        GetReview();
    }, [props.show,MK])

    //Function to update review on the same review id 
    const updateReview = () => {
        fetch(urls.UpdateReview + "?merchant_keys=" + MK + "&review_id=" + rev_id + "&token=" + T + "&device_id=" + T + "&review=" + newreview)
            .then(response => response.json())
            .then(result => {
                if (result.code === 1) {
                    loadreviews();
                    CountReview();

                }
            })
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="sm"
            centered
        >
            <Modal.Header closeButton={true}>
                <Modal.Title as='h5' id="add-address">Edit Review</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="bg-white rounded shadow-sm p-4 mb-5 rating-review-select-page">
                    <Form>
                        <Form.Group>
                            <Form.Label>Your Comment</Form.Label>
                            <Form.Control as="textarea" defaultValue={reviewToEdit.review} onChange={(e) => { setNewReview(e.target.value) }} />
                        </Form.Group>
                        <Form.Group>
                            <Button variant="primary" size="sm" type="button" onClick={props.onHide} onClickCapture={updateReview} > Submit Comment </Button>
                        </Form.Group>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>

    );
}

export default EditReviewModal;