//Copyright by Chaafo pvt ltd


import React from 'react';
import PropTypes from 'prop-types'; 

function HeadingValue (props) {
    	return (
    		<p className={"mb-1 " + props.className}>{props.heading} 
    			{props.heading?
    				<p> {props.value}</p>
    				:""
	            }
    		</p>
    	);
    }

HeadingValue.propTypes = {
  heading: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  valueClassName: PropTypes.string
};
HeadingValue.defaultProps = {
  value: '',
  className:'',
  valueClassName:''
}

export default HeadingValue;