export const SiteColors = {
    primary: 'white',           // bg gradiants
    secondary: '#EDF1F4',       // footer
    textPrimary: '#FFFFFF',     // heading herosection
    textSecondary: '#1A1A1A',   // other headings
    buttonPrimary: '#ED462F',   // buttons
};

export const CardColors = {
    primary: 'linear-gradient(135deg, #ff3008 1%, #fe792b 100%)',  // cart gradiant bg
    secondary: '#F07130',       // cart bg 2
    textPrimary: '#ED462F',     // price color
    textSecondary: '#047AFB',   // other texts
    buttonPrimary: '#60D08E',   // checkout
    buttonSecondary: '#6C757D', // Clear Cart
};
