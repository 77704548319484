//Copyright by Chaafo pvt ltd

import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { GlobalContext } from './GlobalState';
import GlobalContext_ from '../GlobalContext';
import OTPInput, { ResendOTP } from "otp-input-react";
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { urls } from './URLs';
import { Helmet } from 'react-helmet';

function Otp_Mobile() {

    //initialising variables and constants
    var keycheck = sessionStorage.getItem("keycheck");
    const { MK } = useContext(GlobalContext_);
    const [OTP, setOTP] = useState("");
    const history = useHistory();
    const { cart, setCart, setTotal, setCount, setEmpty, setPay } = useContext(GlobalContext);
    var webloadcart = localStorage.getItem("cart");

    //function count is to count the items in cart
    const coUnt = (cart) => {
        let count_cart = 0;
        cart.forEach(item => {
            count_cart += item.qty
        });
        return count_cart;
    }

    var cartC = coUnt(cart);

    async function Otp() {

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en");

        var formdata = new FormData();
        formdata.append("code", OTP);
        formdata.append("token", keycheck);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let result = await fetch(urls.MobileVerification + "?next_step2=firsttime&merchant_keys=" + MK, requestOptions, {

        });
        result = await result.json();

        if (result.code == 1) {
            localStorage.setItem("tokenn", keycheck);
            var T = localStorage.getItem("tokenn");
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "_lang=en");

            var formdata = new FormData();
            formdata.append("cartvalue", webloadcart);
            formdata.append("token", T);
            formdata.append("cart_count", cartC);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(urls.UpdateCart + "?merchant_keys=" + MK, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 1) {
                        var myHeaders = new Headers();
                        myHeaders.append("Cookie", "_lang=en");

                        var formdata = new FormData();
                        formdata.append("token", T);
                        formdata.append("device_id", T);

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        }

                        fetch(urls.LoadCart + "?merchant_keys=" + MK, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.code === 1) {
                                    setCart(result.details.data.item);
                                    setTotal(result.details.data.total);
                                    setCount(result.details.cart_details);
                                    setEmpty('');
                                    setPay(result.details);
                                }
                                history.push("/checkout");
                                localStorage.removeItem('cart');
                            })

                    }
                })
        }
        else {
            alert(result.msg);
        }
    }

    var resendotp = 0;
    var first_name = localStorage.getItem("first_name");
    var contact_phone = localStorage.getItem("contact_phone");
    var email_address = localStorage.getItem("email_address");

    async function customer_register_mobile() {
        if (resendotp == 3) {
            localStorage.removeItem("first_name");
            localStorage.removeItem("contact_phone");
            localStorage.removeItem("email_address");
        }
        else {
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "_lang=en");

            var formdata = new FormData();
            formdata.append("first_name", first_name);
            formdata.append("contact_phone", contact_phone);
            formdata.append("email_address", email_address);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            let result = await fetch(urls.RegisterMobile + "?next_step2=firsttime&contact_phone=" + contact_phone + "&merchant_keys=" + MK, requestOptions, {
            });

            result = await result.json();
            sessionStorage.setItem("keycheck", result.details.token);

            if (result.code === 1) {

                resendotp = resendotp + 1;

            }
            else {
                toast.error(result.msg, {
                    position: "top-center",
                    autoClose: 600
                });
            }
        }
    }

    return (
        <>
            <Helmet>
            <meta name="robots" content="noindex, nofollow" />
             </Helmet>
            <div className="row">
                <div className="col text-center">
                    <h5 className="mt-4 mb-3">Enter the OTP sent to you</h5>
                    <OTPInput
                        style={{ display: 'dflex' }}
                        value={OTP}
                        onChange={setOTP}
                        autoFocus
                        OTPLength={6}
                        otpType="number"
                        disabled={false}
                    />
                    <div className="align-items-center">
                        <Button variant="info" className="font-weight-bold mb-2 mt-3" onClick={Otp} >Verify OTP</Button>
                        <Button variant="info" className="font-weight-bold mb-2 mt-3 ml-1" onClick={customer_register_mobile}>Resend</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Otp_Mobile;
