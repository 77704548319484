//Copyright by Chaafo pvt ltd
//Detail Page starts just after the top search, also it contains recommended items, and all the items according to the categories. 
//Detail page also contains offers, cart, etc. 

import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Container, Form, InputGroup, Dropdown, Button, Tab, Nav, Image, Badge } from 'react-bootstrap';
import ItemsCarousel from './common/ItemsCarousel';
import GalleryCarousel from './common/GalleryCarousel';
import { GlobalContext } from './GlobalState';
import GlobalContext_ from '../GlobalContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './childComponents/OrderOnline.css';
import axios from 'axios';
import { urls } from './URLs';

import OrderSummaryCard from './common/OrderSummaryCard';
import RestaurantInformation from './childComponents/RestaurantInfo';
import BookATable from './childComponents/BookATable';
import RatingsNReviews from './childComponents/RatingsNReviews';
import OrderOnline from './childComponents/OrderOnline';

// import './Detail.css';
import Review from './common/Review';
import { Helmet } from 'react-helmet';

function Detail() {

	const { cart, setCart, setTotal, setCount, setEmpty, setPay } = useContext(GlobalContext);

	var T = localStorage.getItem("tokenn");
	var ORDER_ID = localStorage.getItem("ORDER_ID");
	const [Name, setName] = useState('');
	const [Email, setEmail] = useState('');
	const [Mobile, setMobile] = useState('');
	const [Date, setDate] = useState([]);
	const [DATE, SETDATE] = useState(null);
	const [N_Guest, setN_Guest] = useState('');
	const [Time, setTime] = useState([]);
	const [TIME, SETTIME] = useState(null);
	const [clicked, setClicked] = useState("0");
	const { voucher, MK, restaurantinfo } = useContext(GlobalContext_);
	const [review, setREview] = useState([]);
	const [re_view, setReview] = useState('');
	const [rate_f, setRate_f] = useState(5);
	const [countreview, setCountreview] = useState(0);
	const [search, setSearch] = useState(null);


	//Function to scroll the tab to top
	const scrollTotop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	toast.configure();

	const sortCart = (data) => {
		data.sort((a, b) => {
			if (a.sort_id > b.sort_id) {
				return 1;
			}
			else if (b.sort_id > a.sort_id) {
				return -1;
			}
			else {
				return 0;
			}
		})
		return data;
	}

	//Function to load the cart
	async function Loadcart() {

		var myHeaders = new Headers();
		var T = localStorage.getItem("tokenn");
		myHeaders.append("Cookie", "_lang=en");
		var formdata = new FormData();
		formdata.append("device_id", T);
		formdata.append("token", T);
		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: formdata,
			redirect: 'follow'
		};

		fetch(urls.LoadCart + "?merchant_keys=" + MK, requestOptions)
			.then(response => response.json())
			.then(result => {
				if (result.code === 1) {
					setCart(result.details.data.item);
					setTotal(result.details.data.total);
					setCount(result.details.cart_details);
					setEmpty('');
					setPay(result.details);

				}
			})
	}

	//Function to load the cart according to the situation i.e. user is logged in or not
	async function webLoadCart() {
		if (T) {
			var myHeaders = new Headers();
			myHeaders.append("Cookie", "_lang=en")
			var formdata = new FormData();
			formdata.append("token", T)
			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			}
			fetch(urls.LoadCart + "?merchant_keys=" + MK + "&token=" + T, requestOptions);
		}
		else {
			const raw = localStorage.getItem('cart');
			const cartFetchedFromLocalStorage = JSON.parse(raw);
			setCart(sortCart(cartFetchedFromLocalStorage));

			const tRaw = localStorage.getItem('total');
			const totalFetchedFromLocalStorage = JSON.parse(tRaw);
			setTotal(totalFetchedFromLocalStorage);

			const cRaw = localStorage.getItem('Count');
			const countFetchedFromLocalStorage = JSON.parse(cRaw);
			setCount(countFetchedFromLocalStorage);

			const eRaw = localStorage.getItem('empty');
			const EmptyFetchedFromLocalStorage = JSON.parse(eRaw);
			setEmpty(EmptyFetchedFromLocalStorage);

			const pRaw = localStorage.getItem('pay');
			const payFetchedFromLocalStorage = JSON.parse(pRaw);
			setPay(payFetchedFromLocalStorage);
		}
	}

	//Function to update the cart
	const webLoad = () => {

		var webloadcart = localStorage.getItem("cart");
		var myHeaders = new Headers();
		myHeaders.append("Cookie", "_lang=en");

		var formdata = new FormData();
		formdata.append("cartvalue", webloadcart);
		formdata.append("token", T);
		formdata.append("cart_count", 1);
		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: formdata,
			redirect: 'follow'
		};

		fetch(urls.UpdateCart + "?merchant_keys=" + MK, requestOptions)
			.then(response => response.json())
			.then(result => {
				if (result.code === 1) {
					webLoadCart();
				}

			});
	}

	//Function to search the item from menu
	let cancelToken;
	const Search = async (key) => {

		if (typeof cancelToken != typeof undefined) {
			cancelToken.cancel("canceled")
		}

		cancelToken = axios.CancelToken.source();

		var data = new FormData();
		data.append('item_name', key);

		var config = {
			method: 'post',
			url: urls.SearchItem + '?merchant_keys=' + MK,
			cancelToken: cancelToken.token,
			data: data
		};

		try {
			await axios(config)
				.then((resp) => {

					if (resp.data.code == 1) {
						setSearch(resp.data.details.list)
					}
					else {
						setSearch(null)
					}
				})
		} catch (error) {
		}
	}

	//Function to set the ratings star value
	const getStarValue = ({ value }) => {
		setRate_f(value);
	}

	const getQty = ({ id, quantity }) => {

	}

	//Calling load cart api to show effects in realtime
	useEffect(() => {

		webLoadCart();
	}, [MK])

	//Function to clear the cart by clicking on Clear Cart button
	const clearCart = () => {

		if (T) {

			var myHeaders = new Headers();
			myHeaders.append("Cookie", "_lang=en");

			var formdata = new FormData();
			formdata.append("token", T);
			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			};

			fetch(urls.ClearCart + "?merchant_keys=" + MK, requestOptions)
				.then(response => response.json())
				.then(result => {
					if (result.code === 1) {
						toast.success("Cart is cleared!", {
							position: "top-center",
							autoClose: 400
						});
						setCart(null);
						setCount(null);
					}
					Loadcart();

				})
		}
		else {
			localStorage.setItem("cart", JSON.stringify([]));
			webLoad();
			toast.success("Cart is cleared!", {
				position: "top-center",
				autoClose: 400
			});

		}
	}

	//Function to do total of prices according to the prices
	const subTotal = (cart) => {
		let total = 0;
		cart.forEach(item => {
			total += item.qty * item.price;
		});
		return total;
	}

	//Function to fetch user information
	const GetUserInfo = () => {
		var Token = localStorage.getItem("tokenn");
		fetch(urls.UserInfo + '?merchant_keys=' + MK + '&device_id=' + T + '&device_platform=web&token=' + Token + '&lang=en&_=1648619760308')
			.then(response => response.json())
			.then(result => {
				if (result.code == 1) {
					setName(result.details.data.name);
					setEmail(result.details.data.email_address);
					setMobile(result.details.data.contact_phone);
				}
			})
	}

	//function to fetch the dates on which restaurant is open for table booking
	const GetDate = () => {
		fetch(urls.DateList + '?merchant_keys=' + MK + '&device_id=' + T + '&device_platform=web&token=' + T + '&lang=en&_=1648619760309')
			.then(response => response.json())
			.then(result => {
				if (result.code == 1) {
					setDate(result.details);
				}
			})
	}

	//function to fetch the time at which restaurant is open for table booking
	const GetTime = () => {
		fetch(urls.TimeList + '?merchant_keys=' + MK + '&device_id=' + T + '&device_platform=web&token=' + T + '&lang=en&_=1648619760312')
			.then(response => response.json())
			.then(result => {
				if (result.code == 1) {
					setTime(result.details.data);
				}
			})
	}

	return (
		<>
			<GlobalContext.Consumer>
				{(state) => (
					<>
						<Helmet>
							<meta name="robots" content="noindex, nofollow" />
						</Helmet>
						<Tab.Container defaultActiveKey="first">

							<section className="offer-dedicated-nav bg-white border-top-0 shadow-sm">
								<Container>
									<Row>
										<Col md={12}>
											<span className="restaurant-detailed-action-btn float-right">
												{/* <Button variant='light' size='sm' className="border-light-btn mr-1" type="button"><Icofont icon="heart" className='text-danger' /> Mark as Favourite</Button>
			<Button variant='light' size='sm' className="border-light-btn mr-1" type="button"><Icofont icon="cauli-flower" className='text-success' />  Pure Veg</Button>
			<Button variant='outline-danger' size='sm' type="button"><Icofont icon="sale-discount" />  OFFERS</Button> */}
											</span>
											<Nav id="pills-tab">
												<Nav.Item>
													<Nav.Link eventKey="first">Order Online</Nav.Link>
												</Nav.Item>
												<Nav.Item>
													<Nav.Link eventKey="second">Restaurant Info</Nav.Link>
												</Nav.Item>
												<Nav.Item>
													<Nav.Link eventKey="third" onClick={GetUserInfo}>Book A Table</Nav.Link>
												</Nav.Item>
												<Nav.Item>
													<Nav.Link eventKey="fourth">Ratings & Reviews</Nav.Link>
												</Nav.Item>
												<Nav.Item>
													<Nav.Link eventKey="fifth">Gallery</Nav.Link>
												</Nav.Item>
											</Nav>
										</Col>
									</Row>
								</Container>
							</section>
							<section className="offer-dedicated-body pt-2 pb-2 mt-4 mb-4">
								<Container>
									<Row>
										<Col md={8}>
											<div className="offer-dedicated-body-left">
												<Tab.Content className='h-100'>
													<Tab.Pane eventKey="first">
														<OrderOnline search={search} getQty={getQty} Search={Search} review={review} re_view={re_view} setRate_f={setRate_f} countreview={countreview} ORDER_ID={ORDER_ID} setReview={setReview} getStarValue={getStarValue} />
													</Tab.Pane>

													<Tab.Pane eventKey="second">
														<RestaurantInformation />
													</Tab.Pane>

													<Tab.Pane eventKey="third">
														<BookATable
															Name={Name}
															setName={setName}
															Email={Email}
															setEmail={setEmail}
															GetDate={GetDate}
															SETDATE={SETDATE}
															GetTime={GetTime}
															SETTIME={SETTIME}
															Time={Time}
															Date={Date}
															Mobile={Mobile}
															DATE={DATE}
															TIME={TIME}
															T={T}
														/>
													</Tab.Pane>

													<Tab.Pane eventKey="fourth">
														<RatingsNReviews
															review={review}
															ORDER_ID={ORDER_ID}
															getStarValue={getStarValue}
															setReview={setReview}
															re_view={re_view}
															setCountreview={setCountreview}
															setREview={setREview}
															Loadcart={Loadcart}
															rate_f={rate_f}
														/>
													</Tab.Pane>

													<Tab.Pane eventKey="fifth">
														<div className='position-relative'>
															<GalleryCarousel />
														</div>
													</Tab.Pane>
												</Tab.Content>
											</div>
										</Col>

										<Col className='sticky' >
											<OrderSummaryCard
												pagename
												cart={state.cart}
												total={state.total}
												state={state}
												clearCart={clearCart}
												getQty={getQty}
												T={T}
												pageName='detail'
												voucher={voucher}
												subTotal={subTotal}
												scrollTotop={scrollTotop}

											/>
										</Col>
									</Row>
								</Container>
							</section>

						</Tab.Container>
					</>
				)
				}
			</GlobalContext.Consumer >
		</>
	);
}

export default Detail;