//Copyright by Chaafo pvt ltd
// homeoffers is designed to show offers on home page


import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Icofont from 'react-icofont';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MERCHANTKEY } from '../../App';
import { GlobalContext } from '../GlobalState';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';

const Homeoffers = (props) => {
    var T = localStorage.getItem("tokenn");
    const {MK} = useContext(GlobalContext_);
    

    const { cart, setCart, setTotal, setCount, setEmpty, setPay } = useContext(GlobalContext);

     // funtion to call webloadcart api and renders the cart on time and show the voucher in cart.
    //if user is logged in, that means token is there, therefore if case will be executed. Otherwise, else case will be executed.
    async function Loadcart() {

        var myHeaders = new Headers();

        myHeaders.append("Cookie", "_lang=en");
        var formdata = new FormData();
        formdata.append("device_id", T);
        formdata.append("token", T);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };


        fetch(urls.LoadCart+"?&merchant_keys=" + MK, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 1) {
                    setCart(result.details.data.item);
                    setTotal(result.details.data.total);
                    setCount(result.details.cart_details);
                    setEmpty('');
                    setPay(result.details);
                }

            })
    }

    toast.configure();


    // function to remove voucher from cart
    const REMOVE = () => {
        fetch(urls.RemoveVoucher+'?&merchant_keys=' + MK + '&device_id=' + T)
            .then(response => response.json())
            .then(result => {
                if (result.code == 1) {
                    toast.error("Voucher Removed", {
                        position: "top-center",
                        autoClose: 400
                    });
                    Loadcart();

                }
            })
    }

    //function to apply voucher in cart
    const Coupon = () => {


         if (T && cart != null) {


        fetch(urls.ApplyVoucher+"?merchant_keys=" + MK + "&voucher_name=" + props.vouchername + "&device_id=" + T)
            .then(response => response.json())
            .then(result => {
                if (result.code == 1) {
                    Loadcart();
                    toast.success('Voucher applied', {
                        position: "top-center",
                        autoClose: 400
                    });
                } else {
                    toast.error(result.msg, {
                        position: "top-center",
                        autoClose: 400
                    });
                }
            })
         }
         else {
             toast.error('Either cart is empty or you are not logged in', {
                 position: "top-center",
                 autoClose: 400
             });
         }
    }

    return (
        <GlobalContext.Consumer>
            {(state) => (
                <div className="bg-white rounded text-white mb-1 p-4 clearfix restaurant-detailed-earn-pts card-icon-overlap" style={{boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'}}>
                    <Image fluid className="float-left mr-3" src="/img/earn-score-icon.png" alt='offer coupon' />
                    {T ?
                        props.vouchername == state.total.voucher_name ?
                            <Link className='float-right pt-0 text-primary' onClick={REMOVE}>Remove</Link> :
                            <Link className='float-right pt-0 text-primary' onClick={Coupon}>Apply</Link>
                        :
                        <div>
                            <Link className='float-right pt-0 text-primary' onClick={Coupon}>Apply</Link>
                        </div>
                    }
                    <h6 className="pt-0 text-primary mb-1 font-weight-bold" >{props.vouchername}</h6>
                    <p className="mb-0 marg"> {props.vouchertitle}</p>
                    <p className="mb-0 text-danger font-weight-bold marg">{props.Discount}</p> 
                    <p className="mb-0 marg"> {props.voucherdescription}</p>
                    <div className="icon-overlap">
                        <Icofont icon="sale-discount" />
                    </div>
                </div>
            )}
        </GlobalContext.Consumer>
    )
}

Homeoffers.propTypes = {
    vouchertitle: PropTypes.string.isRequired,
    voucherdescription: PropTypes.string.isRequired,
    Discount: PropTypes.string,
    vouchername: PropTypes.string,
    // imageclassName: PropTypes.string
};


export default Homeoffers;

