//Copyright by Chaafo pvt ltd


import React, { Component, useState } from 'react';
import Icofont from 'react-icofont';
import PropTypes from 'prop-types'; 


function StarRating (props)  {
 
    const [star, setStar]= useState(props.star || 0)             
    
  function handleClick (starValue) {   
    if(!props.disabled) {
      setStar(starValue);

      props.getValue({value:starValue});
    }
  } 

    return (
      <div style={{"fontSize":props.fontSize? props.fontSize : 14}} className='d-inline-block'>             
          <Icofont             
            icon="star"
            className={star >= 1 ? "text-primary" : "text-dark" }          
            onClick={() =>handleClick(1)} 
          />
          <Icofont 
            icon="star"
            className={star >= 2 ? "text-primary" : "text-dark" } 
            onClick={() =>handleClick(2)} 
          />          
          <Icofont 
            icon="star"
            className={star >= 3 ? "text-primary" : "text-dark" } 
            onClick={() =>handleClick(3)} 
          />
          <Icofont           
            icon="star"
            className={star >= 4 ? "text-primary" : "text-dark" } 
            onClick={() =>handleClick(4)} 
          />         
          <Icofont 
            icon="star"
            className={star >= 5 ? "text-primary" : "text-dark" } 
            onClick={() =>handleClick(5)}
          />                                                                                                                                    
      </div>
    );
  }


StarRating.propTypes = {
  star: PropTypes.number,
  disabled: PropTypes.bool,
  fontSize:PropTypes.number,
  getValue: PropTypes.func.isRequired
};
StarRating.defaultProps = {
  star: 0,
  disabled:false
}

export default StarRating;