//Copyright by Chaafo pvt ltd
//Edit Profile Modal appears when user wants to edit profile  

import React, { useState, useContext, useEffect } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { MERCHANTKEY } from '../../App';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GlobalContext } from '../../components/GlobalState';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';

function EditProfileModal1(props) {
    const {MK} = useContext(GlobalContext_);
    var T = localStorage.getItem("tokenn");
    const initialValues = { first_name: "", last_name: "", contact_phone: "" };
    const [formErrors, setFormErrors] = useState({});
    const history = useHistory();
    const [isSubmit, setIsSubmit] = useState(false);
    const [hide, setHIde] = useState('');
    const { merchant, setMerchant } = useContext(GlobalContext);

    //Function to edit profile 
    const EditProfile = () => {

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en");

        var formdata = new FormData();
        formdata.append("first_name", merchant.first_name);
        formdata.append("last_name", merchant.last_name);
        formdata.append("contact_phone", merchant.contact_phone);
        formdata.append("token", T);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(urls.SaveProfile+"?merchant_keys=" + MK, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code == 1) {
                    toast.success(result.msg, {
                        position: "top-center",
                        autoClose: 400
                    });
                    
                    Merchant();
                    submitOntrue();
                }
            })
    }

    //Function to fetch user profile details
    async function Merchant() {

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en");

        var formdata = new FormData();
        formdata.append("token", T);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(urls.UserProfile+"?merchant_keys=" + MK, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code == 1) {
                    setMerchant(result.details.data);

                }
            })
    }

     //Function to fetch user profile details in realtime
    useEffect(() => {

        async function Merchant() {

            var myHeaders = new Headers();
            myHeaders.append("Cookie", "_lang=en");

            var formdata = new FormData();
            formdata.append("token", T);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(urls.UserProfile+"?merchant_keys=" + MK, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code == 1) {
                        setMerchant(result.details.data)
                    }
                })
        }
        Merchant()
    }, [props.show,MK])


    //Function to handle changes in fields of edit profile modal
    const handleChange = (e) => {
        const { name, value } = e.target;
        setMerchant({ ...merchant, [name]: value });
    };


    //Function to handle errors while submitting the changes in profile to get saved
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(merchant));
        setIsSubmit(true);

    };

    const submitOntrue = () => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            setHIde(props.onHide);
            return props.onHide;
        }
    }

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {

            EditProfile();
        }
    }, [formErrors]);

    //Function to validate errors
    const validate = (values) => {
        const errors = {};

        if (!values.first_name) {
            errors.first_name = "*first_name is required!";
        }
        if (!values.last_name) {
            errors.last_name = "*last_name is required!";
        }
        if (!values.contact_phone) {
            errors.contact_phone = "*contact_phone is required!";
        }

        return errors;
    };

    return (
        <GlobalContext.Consumer>
            {(state) => (
                <Modal
                    show={props.show}
                    onHide={props.onHide}
                    size="sm"
                    centered
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title as='h5' id="edit-profile">Edit Profile</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form>
                            <div className="form-row">
                               <Form.Group className="col-md-12 mb-0">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" onChange={handleChange} name='first_name' placeholder="Enter first name" defaultValue={state.merchant.first_name} />
                                </Form.Group>
                                <p className='Val'>{formErrors.first_name}</p>
                                <Form.Group className="col-md-12 mb-0">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" onChange={handleChange} name='last_name' placeholder="Enter last name" defaultValue={state.merchant.last_name} />
                                </Form.Group>
                                <p className='Val'>{formErrors.last_name}</p>
                                <Form.Group className="col-md-12 mb-0">
                                    <Form.Label>Mobile No.</Form.Label>
                                    <Form.Control type="number" onChange={handleChange} name='contact_phone' placeholder="Mobile Phone" defaultValue={state.merchant.contact_phone} />
                                </Form.Group>
                                <p className='Val'>{formErrors.contact_phone}</p>
                            </div>
                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button type='button' onClick={props.onHide} variant="outline-primary" className="d-flex w text-center justify-content-center">CANCEL</Button>

                        <Button type='button' onClick={handleSubmit}  variant="primary" className='d-flex w text-center justify-content-center' >UPDATE</Button>

                    </Modal.Footer>
                </Modal>
            )}
        </GlobalContext.Consumer>

    );
}

export default EditProfileModal1;