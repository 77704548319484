//Copyright by Chaafo pvt ltd
//TopSearch contains header, background image of merchant, categories carousel, banner, logo and locate me bar.

import React, { useContext, useState, useEffect, usePos } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Form, Image } from 'react-bootstrap';
import Icofont from 'react-icofont';
import OwlCarousel from 'react-owl-carousel3';
import ProductBox from './ProductBox';
import CategoriesCarousel from '../common/CategoriesCarousel';
import GlobalContext_ from '../../GlobalContext';

import { Helmet } from 'react-helmet';
// import { SiteColors } from '../../constants/colorTheme';

function TopSearch() {
    const { banner, restaurantinfo, background, locate: loc, MK, } = useContext(GlobalContext_);
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [status, setStatus] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [map, setMap] = useState([]);

    //function to fetch user's current location
    const fetchMap = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyB4mMpI8D12QHkyehxumzNsd2SinFY8oZk`)
                .then(response => response.json())
                .then(result => { setMap(result.results[0].formatted_address) });
            loc = map;
            // Do something with the response
            setIsLoaded(true);
        });
    };

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <section style={{ backgroundImage: `url(${background})` }} className="pt-5 BG_style pb-5 homepage-search-block position-relative">
                <Container >
                    <div className="banner-overlay"></div>
                    <Row className="d-flex align-items-center">
                        <Col md={8} className='card-slide-in-left'>
                            <div className="homepage-search-title">
                                <h1 className="mb-2 font-weight-normal" style={{ color: 'white' }} ><span className="font-weight-bold" >{restaurantinfo.merchant_name}</span></h1><br></br>
                                <p className="mb-5  font-weight-normal" style={{ color: 'white', backgroundColor: '#00000087', padding: '5px' }} >{restaurantinfo.information}</p>
                            </div>
                            <div className="homepage-search-form">
                                <Form className="form-noborder">
                                    <div className="form-row">
                                        <Form.Group className='col-lg-12 col-md-12 col-sm-12'>
                                            <Form.Control type="text" placeholder="Enter your delivery location" value={loc} size='lg' />
                                            <Link className="locate-me" onClick={fetchMap}><Icofont icon='ui-pointer' /> Locate Me</Link>
                                        </Form.Group>
                                    </div>
                                </Form>
                            </div>
                        <CategoriesCarousel />
                        </Col>
                        <Col md={4} className='card-slide-in-right'>
                            <div className="osahan-slider pl-4 pt-3 ">
                                <OwlCarousel nav loop {...options2} className="homepage-ad owl-theme ">
                                    {(banner?.map((banner, index) =>
                                        <div className="item" key={index} >
                                            <ProductBox
                                                image={banner}
                                                imageClass='BTS rounded'
                                            />
                                        </div>
                                    ))}
                                </OwlCarousel>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

const options2 = {
    responsive: {
        0: {
            items: 1,
        },
        764: {
            items: 1,
        },
        765: {
            items: 1,
        },
        1200: {
            items: 1,
        },
    },
    lazyLoad: true,
    loop: true,
    autoplay: true,
    autoplaySpeed: 800,
    dots: false,
    autoplayTimeout: 2000,
    nav: true,
    navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
    autoplayHoverPause: true,
}

export default TopSearch;