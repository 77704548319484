//Copyright by Chaafo pvt ltd
//Delete Address modal asks for the surety whether the user wants to delete the address or not.



import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { MERCHANTKEY } from '../../App';
import { GlobalContext } from '../../components/GlobalState';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';

function DeleteAddressModal(props) {
	const {MK} = useContext(GlobalContext_);
	var T = localStorage.getItem("tokenn");
	var del_id = localStorage.getItem("del_id");
	const { address, setAddress, setLocate } = useContext(GlobalContext);


  //Function to fetch address book
   async function Address() {
      const response = await fetch(urls.AddressList+'?merchant_keys=' + MK + '&device_platform=web&lang=en&_=1645172259444&token=' + T);
      const data = await response.json();
      if (data.code == 1) {
         setAddress(data.details.data);
      }
	  if(data.details.data[0].id==del_id){
		setLocate(false);
	  }
   }
   
   //Function to delete address
		const Deleteaddress = () => {
			var myHeaders = new Headers();
			myHeaders.append("Cookie", "_lang=en");

			var formdata = new FormData();
			formdata.append("token", T);
			formdata.append("id", del_id);

			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			};
			Address();
			fetch(urls.DeleteAddress+"?merchant_keys=" + MK, requestOptions)
			.then(response => response.json())
			      .then(result => {
			         if (result.code === 1) {
			            Address();
			            toast.success(result.msg, {
			               position: "top-center",
			               autoClose: 500
			            });
			            if (address.length == 1) {
			               setAddress([]);
			            }
			         }
			      })

		}

		toast.configure();
		
	return (
		//Delete Address Modal appears when user clicks on delete button of the address card.

		<Modal
			show={props.show}
			onHide={props.onHide}
			centered
			size="sm"
		>
			<Modal.Header closeButton={true}>
				<Modal.Title as='h5' id="delete-address">Delete</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<p className="mb-0 text-black">Are you sure you want to delete this Address?</p>
			</Modal.Body>

			<Modal.Footer>
				<Button type='button' onClick={props.onHide} variant="outline-primary" className="d-flex w text-center justify-content-center">CANCEL</Button>
				<Button type='button' variant="primary" onClick={props.onHide} onClickCapture={Deleteaddress} className='d-flex w text-center justify-content-center'>DELETE</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default DeleteAddressModal;