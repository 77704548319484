//Copyright by Chaafo pvt ltd
// Designed to show past orders


import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Image, Media } from 'react-bootstrap';
import Icofont from 'react-icofont';
import { MERCHANTKEY } from '../../App';
import { urls } from '../URLs';
import { GlobalContext } from '../../components/GlobalState';
import GlobalContext_ from '../../GlobalContext';

function OrderCard(props) {

    const {MK} = useContext(GlobalContext_);
    var T = localStorage.getItem("tokenn");
    const history = useHistory();
    const [s, setS] = useState('')
    const [Customerdetails, setCustomerdetails] = useState([]);
    const [OrderDetails1, setOrderDetails1] = useState([]);
    const [Merchantdetails, setMerchantdetails] = useState([]);
    const [OrderDetails3, setOrderDetails3] = useState('');

    const { cart, setCart, setTotal, setCount, setEmpty, setPay } = useContext(GlobalContext);

    //Invoice function is to generate invoice
    //Button is given in order card to generate invoice
    useEffect(() => {
        async function invoice() {
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "_lang=en");

            var formdata = new FormData();
            formdata.append("token", T);
            formdata.append("id", props.orderNumber);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(urls.Invoice + "?merchant_keys=" + MK, requestOptions)
                .then(response => response.json())
                .then(result1 => (setCustomerdetails(result1.details.customer_details), setOrderDetails1(result1.details.htmlarray), setMerchantdetails(result1.details.merchant_details), setOrderDetails3(result1.details.cartamount))
                )
        }
        invoice()
    },
        [MK]);


    async function Loadcart() {

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en");

        var T = localStorage.getItem("tokenn");

        var formdata = new FormData();
        formdata.append("device_id", T);
        formdata.append("token", T);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };


        fetch(urls.LoadCart + "?merchant_keys=" + MK, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 1) {
                    setCart(result.details.data.item);
                    setTotal(result.details.data.total);
                    setCount(result.details.cart_details);
                    setEmpty('');
                    setPay(result.details);
                }
            })
    }

    //Function to reorder all the items ordered previously 
    const reorder = () => {

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en");

        var formdata = new FormData();
        formdata.append("id", props.orderNumber);
        formdata.append("token", T);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(urls.Reorder + "?merchant_keys=" + MK+"&device_id="+T, requestOptions)
            .then(response => response.json())
            .then(result => {
                setS(result)
                Loadcart();
            })
    }


    //Invoice function is to generate invoice
    async function invoiceGET() {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en");

        var formdata = new FormData();
        formdata.append("token", T);
        formdata.append("id", props.orderNumber);
        localStorage.setItem("ORDER_ID", props.orderNumber);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(urls.Invoice + "?merchant_keys=" + MK, requestOptions)
            .then(response => response.json())
            .then(result1 => {
                if (result1.code == 1) {
                    history.push('/Invoice');
                }
            })
    }

    const trak= () => {
        localStorage.setItem("ORDER_ID", props.orderNumber);
        history.push("/track-order");
    }


    return (
        <div className="bg-white card mb-4 order-list shadow-sm">

            <div className="gold-members p-4">
                <Media>
                    <Image className="mr-4" src={props.image} alt={props.imageAlt} />
                    <Media.Body>
                        {props.deliveredDate ?
                            (
                                <span className="float-right text-info"> <Icofont icon="clock-time" className="ml-2" />   {props.deliveredDate}
                                    <Icofont icon="check-circled" className="text-success ml-1" />
                                </span>
                            )
                            : ""
                        }
                        <h6 className="mb-2">
                            <Link to={props.detailLink} className="text-black">{props.orderTitle} </Link>
                        </h6>
                        <p className="text-gray mb-1">
                            {props.address}
                        </p>
                        <p className="text-gray mb-3">
                            <Link onClick={trak}>
                                <Icofont icon="list" /> VIEW ORDER #{props.orderNumber}</Link>
                            <div className="text-dark mt-2 mb-0">
                                {(OrderDetails1?.map(order =>
                                    <span>{order.item_name}  <span> X </span>{order.qty}, </span>
                                ))}
                            </div>
                        </p>

                        <hr />
                        <div className="float-right">
                            <Link className="btn btn-sm btn-outline-primary mr-1" to='/Invoice' onClick={invoiceGET}> Invoice</Link>
                            <Link className="btn btn-sm btn-primary" to={props.detailLink} onClick={reorder}><Icofont icon="refresh" /> REORDER</Link>
                        </div>
                        <p className="mb-0 text-black text-primary pt-2">
                            <span className="text-black font-weight-bold"> Total Paid:</span> {props.orderTotal}
                        </p>
                    </Media.Body>
                </Media>
            </div>
        </div>
    );
}


OrderCard.propTypes = {

    image: PropTypes.string.isRequired,
    imageAlt: PropTypes.string,
    orderNumber: PropTypes.string.isRequired,
    orderDate: PropTypes.string.isRequired,
    deliveredDate: PropTypes.string,
    orderTitle: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    orderProducts: PropTypes.string.isRequired,
    helpLink: PropTypes.string.isRequired,
    detailLink: PropTypes.string.isRequired,
    orderTotal: PropTypes.string.isRequired,
};
export default OrderCard;
