//Copyright by Chaafo pvt ltd
//Designed rating bars to show star rating percentage


import React, { Component,useState } from 'react';
import {ProgressBar} from 'react-bootstrap';
import PropTypes from 'prop-types'; 


function RatingBar(props){

    return (
      <div className="rating-list align-items-center">
        {props.leftText?
        	<div className=" text-black d-flex" style={{marginRight: '1.5rem'}}>
	           {props.leftText}
	        </div>
	        :
	        ""
	    }
        <div className="rating-list-center" style={{width: '80%'}} >
           <ProgressBar  now={props.barValue} variant={props.variant}>
           </ProgressBar>
        </div>
        <div className="text-black ml-1">{props.barValue} %</div>
     </div>
    );
  }


RatingBar.propTypes = {
  leftText: PropTypes.string,
  barValue: PropTypes.number,
  variant: PropTypes.string
};
RatingBar.defaultProps = {
  variant: "info",
}

export default RatingBar;