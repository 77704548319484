//Copyright by Chaafo pvt ltd
//This file is called to change password

import React, { useEffect, useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Container, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { urls } from './URLs';
import GlobalContext_  from '../GlobalContext';

function Forgot() {
   
    const MK = useContext(GlobalContext_);
    const [mobile, setMobile] = useState("");
    const history = useHistory();
    toast.configure();

    //Function to change password according to phone no.
    async function forgot() {

        let useritem = { mobile };

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en");

        var formdata = new FormData();
        formdata.append("user_email", mobile);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let result = await fetch(urls.ForgotPassword+"?merchant_keys=" + MK, requestOptions, {

        });

        result = await result.json();
        localStorage.setItem("user-info", (useritem));
        sessionStorage.setItem("keycheck", result.details.token);

        if (result.code === 1) {
            history.push('/otp');
        }
        else {
            toast.error(result.msg, {
                position: "top-center",
                autoClose: 600
            });
            history.push("/Forgot")
        }
    }

    return (
        <>
       
            <Container fluid className='bg-white'>
            <Row>
                <Col md={4} lg={6} className="d-none d-md-flex bg-image"></Col>
                <Col md={8} lg={6}>
                    <h3 className="login-heading mt-5 text-center">ch<span>A</span>afo</h3>
                    <div className="login align-items-center mt-5 mb-4">
                        <Container>
                            <Row>
                                <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
                                    <h3 className="login-heading mt-5">Account Recovery</h3>
                                    <Form>
                                        <div className="form-label-group mb-4">
                                            <Form.Control type="text" id="inputNumber" onChange={(e) => setMobile(e.target.value)} maxLength={10} placeholder="Mobile No." />
                                            <Form.Label htmlFor="inputNumber">Mobile No.</Form.Label>
                                        </div>
                                        <Link className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2" onClick={forgot}>Send Otp</Link>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>
        </Container>
            </>
       
    );
}

export default Forgot;