//Copyright by Chaafo pvt ltd
//Address card is the card in which the saved addresses are shown. 
//Here, edit, delete and apply buttons are given to edit, delete and apply the address respectively.

import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Card, Media } from 'react-bootstrap';
import Icofont from 'react-icofont';
import PropTypes from 'prop-types';
import { GlobalContext } from '../../components/GlobalState';
import { MERCHANTKEY } from '../../App';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { counter } from '@fortawesome/fontawesome-svg-core';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';

function AddressCard(props) {
   const {MK} = useContext(GlobalContext_);
   var T = localStorage.getItem("tokenn");
   const [addr, setAddr] = useState('');

   const {  setCart, setTotal, setCount, setEmpty, setPay } = useContext(GlobalContext);


   // funtion to call webloadcart api and renders the cart on time.
   async function Loadcart() {

      var myHeaders = new Headers();

      var T = localStorage.getItem("tokenn");
      myHeaders.append("Cookie", "_lang=en");

      var formdata = new FormData();
      formdata.append("device_id", T);
      formdata.append("token", T);

      var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: formdata,
         redirect: 'follow'
      };

      fetch(urls.LoadCart+"?merchant_keys=" + MK, requestOptions)
         .then(response => response.json())
         .then(result => {
            if (result.code === 1) {
               setCart(result.details.data.item);
               setTotal(result.details.data.total);
               setCount(result.details.cart_details);
               setEmpty('');
               setPay(result.details);
            }
         })
   }

   toast.configure();

   // function applyaddress to apply address on cart as delivery address
   const applyaddress = () => {


      fetch(urls.SetAddress+"?street=" + props.address + "&city=&state=&zipcode=&country_code=&location_name=&merchant_keys=" + MK + "&address_id=" + props.id + "&device_id=" + T)
         .then(response => response.json())
         .then(result => {
            if (result.code === 1) {
               Loadcart();
               setAddr('1');
               window.scrollTo({
                  top: 350,
                  behavior: 'smooth'
               })
               toast.success("Address applied", {
                  position: "top-center",
                  autoClose: 400
               });
            }
            else {
               toast.error(result.msg, {
                  position: "top-center",
                  autoclose: 500
               });
            }
         })

   }

   //function to set address id in local storage
   const passid = () => {
      localStorage.setItem("del_id", props.id);

   }


   return (
      <>
         <GlobalContext.Consumer>

            {(state) => (
               //saved addresses will be shown in this card.
               <Card className={"bg-white addresses-item mb-4 d-flex flex-wrap" + (props.boxClass)}>
                  <div className="gold-members p-4">
                     <Media >
                        <div className="mr-3 "><Icofont icon={props.icoIcon} className={props.iconclassName} /></div>
                        <div className="media-body">
                           <h6 className="mb-1 text-secondary">{props.title}</h6>
                           <p className="text-black">{props.address}
                           </p>
                           <p className="mb-0 text-black font-weight-bold d-flex flex-wrap">

                              <Link className="text-primary mr-3" to="#" onClick={props.onEditClick} onClickCapture={passid}><Icofont icon="ui-edit" />EDIT</Link>
                              <Link className="text-danger mr-3" to="#" onClick={props.onDeleteClick} onClickCapture={passid} ><Icofont icon="ui-delete" />DELETE</Link>
                              {state.cart == null ? '' :
                                 state.count.street == props.address ?
                                    <h7 className="text-success" ><Icofont icon="tick-mark " />APPLIED</h7> :
                                    <Link className="text-danger" to="#" onClick={applyaddress} onClickCapture={passid}><Icofont icon="tick-mark " /> APPLY</Link>}
                           </p>

                        </div>
                     </Media>
                  </div>
               </Card>
            )}
         </GlobalContext.Consumer>
      </>
   );
}

AddressCard.propTypes = {
   id: PropTypes.number.isRequired,
   title: PropTypes.string.isRequired,
   icoIcon: PropTypes.string.isRequired,
   iconclassName: PropTypes.string,
   address: PropTypes.string,
   onEditClick: PropTypes.func.isRequired,
   onDeleteClick: PropTypes.func.isRequired

};

export default AddressCard;

