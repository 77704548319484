//Copyright by Chaafo pvt ltd
//Addresses file is used in account section to show all the saved addresses

import React, { useState, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import AddAddressModal from '../modals/AddAddressModal';
import DeleteAddressModal from '../modals/DeleteAddressModal';
import AddressCard from '../common/AddressCard';
import ChooseAddressCard from '../common/ChooseAddressCard';
import { GlobalContext } from '../../components/GlobalState';
import { MERCHANTKEY } from '../../App';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';

import { Helmet } from 'react-helmet';


function Addresses() {
    const {MK} = useContext(GlobalContext_);
    var T = localStorage.getItem("tokenn");

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAddressModal, setShowAddressModal] = useState(false);
    const { address, setAddress } = useContext(GlobalContext);

    //Function to fetch address list
    async function Address() {
        const response = await fetch(urls.AddressList + '?merchant_keys=' + MK + '&token=' + T);
        const data = await response.json();
        if (data.code == 1) {
            setAddress(data.details.data);
        }
    }
    if (address == null) {
        Address();
    }


    const hideDeleteModal = () => setShowDeleteModal(false);
    const hideAddressModal = () => setShowAddressModal(false);

    return (
        <GlobalContext.Consumer>
            {(state) => (
                <>
                 <Helmet>
                 <meta name="robots" content="noindex, nofollow" />
                   </Helmet>
                    <AddAddressModal show={showAddressModal} onHide={hideAddressModal} />
                    <DeleteAddressModal show={showDeleteModal} onHide={hideDeleteModal} />
                    <div className='p-4 bg-white shadow-sm'>
                        <Row>

                            <Col md={12}>
                                <h4 className="font-weight-bold mt-0 mb-3">Manage Addresses</h4>
                            </Col>

                            <Col md={6}>
                                <ChooseAddressCard
                                    type="newAddress"
                                    onAddNewClick={() => setShowAddressModal(true)}
                                />
                            </Col>
                            {
                                state.address == null ? null :
                                    (state.address.map(address =>
                                        <Col md={6}>

                                            <AddressCard
                                                boxClass="border border-primary shadow"
                                                id={address.id}
                                                title='Home'
                                                icoIcon='ui-home'
                                                iconclassName='icofont-3x'
                                                address={address.address}
                                                onEditClick={() => setShowAddressModal(true)}
                                                onDeleteClick={() => setShowDeleteModal(true)}
                                            />
                                        </Col>
                                    ))

                            }
                        </Row>
                    </div>
                </>
            )}
        </GlobalContext.Consumer>
    );
}

export default Addresses;

