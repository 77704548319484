//Copyright by Chaafo pvt ltd
//Coupon Card is designed to show coupons or vouchers

import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Image, Button } from 'react-bootstrap';
import { GlobalContext } from '../GlobalState';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import copy from "copy-to-clipboard";
import { RESTAURANT, MERCHANTKEY } from '../../App';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';

function CouponCard(props) {
    const [copyText, setCopyText] = useState('');

    var T = localStorage.getItem("tokenn");
   
    const {MK} = useContext(GlobalContext_);
    const [message, setMessage] = useState(null);
    const { cart, setCart, setTotal, setCount, setEmpty, Pay, setPay, address, setAddress, total } = useContext(GlobalContext);

    // function to set voucher name to copyText
    const handleCopyText = (e) => {
        setCopyText(e.target.value);
    }

    // Function to copy voucher in clipboard
    const copyToClipboard = () => {
        copy(props.couponCode);
        toast.success('You have copied', {
            position: "top-center",
            autoClose: 400
        });

    }

    // funtion to call webloadcart api and renders the cart on time and show the voucher in cart.
    //if user is logged in, that means token is there, therefore if case will be executed. Otherwise, else case will be executed.
    async function Loadcart() {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en");

        var formdata = new FormData();
        formdata.append("device_id", T);
        formdata.append("token", T);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(urls.LoadCart+"?merchant_keys=" + MK + "&token=" + T, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 1) {
                    setCart(result.details.data.item);
                    setTotal(result.details.data.total);
                    setCount(result.details.cart_details);
                    setEmpty('');
                    setPay(result.details); 
                }
            })
    }


    // Function to apply voucher in cart
    const VOUCHER = () => {

        if (T && cart != null) {

        fetch(urls.ApplyVoucher+"?merchant_keys=" + MK + "&voucher_name=" + props.couponCode + "&device_id=" + T)
            .then(response => response.json())
            .then(result => {
                if (result.code == 1) {
                    Loadcart();
                    setMessage(result.msg);

                    toast.success('Voucher applied!', {
                        position: "top-center",
                        autoClose: 400
                    });
                } else {
                    toast.error(result.msg, {
                        position: "top-center",
                        autoClose: 400
                    });
                }
            })
        }
        else {
            toast.error('Either cart is empty or you are not logged in', {
                position: "top-center",
                autoClose: 400
            });
        }
    }

    return (
        <GlobalContext.Consumer>
            {(state) => (
                <div className={"card offer-card shadow-sm mb-4" + (props.noBorder ? ' border-0' : '')}>
                    <div className="card-body">
                        {(props.logoImage || props.couponCode) ?
                            (<h5 className="card-title">
                                {props.logoImage ?
                                    <Image src={props.logoImage} alt={props.imageAlt} className={props.imageclassName} />
                                    : ""
                                }
                                {props.couponCode ?
                                    props.couponCode
                                    : ""
                                }
                            </h5>)
                            : ""
                        }
                        <h6 className="card-subtitle mb-2 text-block">{props.title}</h6>
                        {props.subTitle ?
                            <p className="card-text">
                                {props.subTitle}
                            </p>
                            : ""
                        }
                        {props.discount ?
                            <p className="mb-2 text-danger font-weight-bold">{props.discount}</p>
                            : ""}

                        {props.copyBtnText && T ?


                            props.couponCode == state.total.voucher_name ?
                                " " :
                                <Link to={"/checkout"} className="card-link" onChange={handleCopyText} onClick={copyToClipboard} >{props.copyBtnText}</Link>


                            : <Link to="/">
                                <Button variant="link" className="card-btn mr-3 p-0" onChange={handleCopyText} onClick={copyToClipboard} >{props.copyBtnText}</Button>
                            </Link>
                        }
                        {props.moreLinkText && T ?
                            props.couponCode == state.total.voucher_name ?
                                <h8 className="card-link" style={{ color: 'cadetblue' }}>VOUCHER APPLIED</h8>
                                : <Link to="/checkout" onClick={VOUCHER} className="card-link">{props.moreLinkText}</Link>

                            : <Link to="/" className="card-link" onClick={VOUCHER}>{props.moreLinkText}</Link>
                        }

                    </div>
                </div>
            )}
        </GlobalContext.Consumer>
    );
}

CouponCard.propTypes = {
    title: PropTypes.string.isRequired,
    logoImage: PropTypes.string,
    subTitle: PropTypes.string,
    discount: PropTypes.string,
    imageAlt: PropTypes.string,
    imageclassName: PropTypes.string,
    morelinkUrl: PropTypes.string,
    moreLinkText: PropTypes.string,
    copyBtnText: PropTypes.string,
    couponCode: PropTypes.string,
    noBorder: PropTypes.bool,
};
CouponCard.defaultProps = {
    logoImage: '',
    subTitle: '',
    imageAlt: '',
    imageclassName: '',
    morelinkUrl: '#',
    moreLinkText: 'APPLY',
    copyBtnText: '',
    couponCode: '',
    discount: '',
    noBorder: true,
}

export default CouponCard;

