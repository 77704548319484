//Copyright by Chaafo pvt ltd
//Header is designed to show all the headings and tabs 

import React, { useState, useEffect, useContext, useLocation } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import { Navbar, Nav, Container, NavDropdown, Image, Badge, Button, Toast } from 'react-bootstrap';
import DropDownTitle from '../common/DropDownTitle';
import CartDropdownHeader from '../cart/CartDropdownHeader';
import CartDropdownItem from '../cart/CartDropdownItem';
import Icofont from 'react-icofont';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalContext } from '../../components/GlobalState';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';

function Header() {

    const { MK, restaurantinfo } = useContext(GlobalContext_);

    var ORDER_ID = localStorage.getItem("ORDER_ID");

    const [Setting, setSetting] = useState([]);
    const [isNavExpanded, setNavExpanded] = useState(false);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);

    const history = useHistory('');

    var T = localStorage.getItem("tokenn");
    var myHeaders = new Headers();
    myHeaders.append("Cookie", "_lang=en");
    var formdata = new FormData();
    formdata.append("device_id", T);
    formdata.append("token", T);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    const setIsNavExpanded = (isNavExpanded) => {
        setNavExpanded(isNavExpanded);
    }
    const closeMenu = () => {
        setNavExpanded(false);
    }

    // removetoken function is for logout
    const removetoken = () => {
        localStorage.removeItem('tokenn');
        localStorage.removeItem("ORDER_ID");
        localStorage.removeItem("cart");
        window.location.reload(true);
        setCart(null);
        setCount(null);
        toast.success('Logged out Succesfully', {
            position: 'top-center',
            autoClose: 400
        });
        history.push('/');
    }

    const { cart, setCart, setTotal, setCount, setEmpty, setPay, merchant, setMerchant } = useContext(GlobalContext);

    toast.configure();

    const [show2, setShow2] = useState(false);

    const showDropdown2 = (e) => {
        setShow2(!show);
    }
    const hideDropdown2 = e => {
        setShow2(false);
    }

    const [show3, setShow3] = useState(false);

    const showDropdown3 = (e) => {
        setShow3(!show);
    }
    const hideDropdown3 = e => {
        setShow3(false);
    }

    //function to scroll the tab to top
    const scrollTotop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    //function to fetch user profile details
    useEffect(() => {
        async function Merchant1() {

            await fetch(urls.UserProfile + "?merchant_keys=" + MK, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code == 1) {
                        setMerchant(result.details.data)
                    }
                })
        }
        Merchant1()
    }, [MK]);

    // function to get app settings for user
    useEffect(() => {
        async function Settings() {
            const response = await fetch(urls.AppSettings + '?merchant_keys=' + MK);
            const data = await response.json();
            setSetting(data.details.merchant_globaltheme[0]);
        }
        Settings();
    }, [MK])

    var color = Setting.color_code_web;

    const Style_ = {
        backgroundColor: color
    }

    // function to redirect the user to the page where user can see his/her past orders
    const redirect = () => {
        history.push('/myaccount/orders');
    }

    return (
        <GlobalContext.Consumer>
            {(state) => (
                <div className='headerfix'>
                    <Navbar onToggle={setIsNavExpanded}
                        expanded={isNavExpanded} color="light" expand='lg' className="navbar-light osahan-nav shadow-sm">
                        <Container>
                            <Navbar.Brand eventKey={0} as={NavLink} exact to="/">
                                <Image src={restaurantinfo.merchant_image} alt={restaurantinfo.merchant_name} />
                            </Navbar.Brand>
                            <Navbar.Toggle />
                            <Navbar.Collapse id="navbarNavDropdown">
                                <Nav activeKey={0} className="ml-auto" onSelect={closeMenu}>
                                    <Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to="/" onClick={scrollTotop}>
                                        Home <span className="sr-only">(current)</span>
                                    </Nav.Link>
                                    <Nav.Link eventKey={1} as={NavLink} activeclassname="active" to="/offers" onClick={scrollTotop}>
                                        <Icofont icon='sale-discount' /> Vouchers <Badge variant="danger">New</Badge>
                                    </Nav.Link>
                                    <Nav.Link eventKey={2} as={NavLink} activeclassname="active" to="/TopOffers" onClick={scrollTotop}>
                                        Top Offers
                                    </Nav.Link>
                                    {ORDER_ID ?
                                        // <NavDropdown show={show1} onMouseEnter={showDropdown1} onMouseLeave={hideDropdown1} title="Order Status" >
                                        <Nav.Link eventKey={3} as={NavLink} activeclassname="active" to="/track-order" onClick={scrollTotop}>Track Order</Nav.Link> : ''}

                                    {T ?
                                        state.count ?
                                            <NavDropdown activeclassname="active" className="dropdown-cart" show={show3} onMouseEnter={showDropdown3} onMouseLeave={hideDropdown3}
                                                title={
                                                    <DropDownTitle
                                                        className='d-inline-block'
                                                        faIcon='shopping-basket'
                                                        iconClass='mr-1'
                                                        title='Cart'
                                                        badgeClass='ml-1'
                                                        badgeVariant='success'
                                                        badgeValue={Number(state.count.cart_count)}
                                                    />
                                                }

                                            >
                                                <div className="dropdown-cart-top shadow-sm">
                                                    {
                                                        <CartDropdownHeader
                                                            className="dropdown-cart-top-header p-4"
                                                            title={restaurantinfo.merchant_name}
                                                            subTitle={restaurantinfo.address}
                                                            image={restaurantinfo.merchant_image}
                                                            imageClass="img-fluid mr-3"
                                                            imageAlt="osahan"
                                                            NavLinkUrl="#"
                                                            NavLinkText="View Full Menu"
                                                        />
                                                    }
                                                    <div className="dropdown-cart-top-body border-top p-4">
                                                        {
                                                            state.cart == null ? (<div className="text-center bg-white rounded shadow-sm mb-2">
                                                                {state.empty}
                                                            </div>) :
                                                                state.cart.length > 0 ?
                                                                    <div>
                                                                        {(state.cart?.map(carts =>
                                                                            <CartDropdownItem
                                                                                icoIcon='ui-press'
                                                                                iconClass='text-success food-item'
                                                                                title={carts.item_name}
                                                                                qty={carts.qty}
                                                                                id={Number(carts.item_id)}
                                                                                price={carts.discounted_price}
                                                                            />
                                                                        ))}


                                                                        <div className="dropdown-cart-top-footer border-top p-4">
                                                                            {state.total ?
                                                                                <div>
                                                                                    <p className="mb-0 font-weight-bold text-secondary">Sub Total <span className="float-right text-dark">₹{Math.round(Math.abs(state.total.subtotal))}</span></p>
                                                                                </div>
                                                                                : <div></div>
                                                                            }

                                                                            <small className="text-info">Extra charges may apply</small>
                                                                        </div>
                                                                        <div className="dropdown-cart-top-footer border-top p-2">
                                                                            {!T ?
                                                                                <NavDropdown.Item eventKey={5.1} as={Link} className="btn btn-success btn-block py-3 text-white text-center dropdown-item" to="/MobileVerification" onClick={scrollTotop}> Checkout</NavDropdown.Item> :
                                                                                <NavDropdown.Item eventKey={5.1} as={Link} className="btn btn-success btn-block py-3 text-white text-center dropdown-item" to="/checkout" onClick={scrollTotop}> Checkout</NavDropdown.Item>}
                                                                        </div>
                                                                    </div>

                                                                    : <div className="text-center bg-white rounded shadow-sm mb-2">
                                                                        {state.empty}
                                                                    </div>
                                                        }

                                                    </div>

                                                </div>
                                            </NavDropdown> : ""
                                        :
                                        state.cart.length != 0 ?
                                            <NavDropdown activeclassname="active" className="dropdown-cart" show={show3} onMouseEnter={showDropdown3} onMouseLeave={hideDropdown3}
                                                title={
                                                    <DropDownTitle
                                                        className='d-inline-block'
                                                        faIcon='shopping-basket'
                                                        iconClass='mr-1'
                                                        title='Cart'
                                                        badgeClass='ml-1'
                                                        badgeVariant='success'
                                                        badgeValue={state.cart.length|| 0}
                                                    />
                                                }
                                            >
                                                <div className="dropdown-cart-top shadow-sm">
                                                    {
                                                        <CartDropdownHeader
                                                            className="dropdown-cart-top-header p-4"
                                                            title={restaurantinfo.merchant_name}
                                                            subTitle={restaurantinfo.address}
                                                            image={restaurantinfo.merchant_image}
                                                            imageClass="img-fluid mr-3"
                                                            imageAlt="osahan"
                                                            NavLinkUrl="#"
                                                            NavLinkText="View Full Menu"
                                                        />
                                                    }
                                                    <div className="dropdown-cart-top-body border-top p-4">
                                                        {
                                                            state.cart == null ? (<div className="text-center bg-white rounded shadow-sm mb-2">
                                                                {state.empty}
                                                            </div>) :
                                                                state.cart.length > 0 ?
                                                                    <div>
                                                                        {(state.cart?.map(carts =>
                                                                            <CartDropdownItem
                                                                                icoIcon='ui-press'
                                                                                iconClass='text-success food-item'
                                                                                title={carts.item_name}
                                                                                qty={carts.qty}
                                                                                id={Number(carts.item_id)}
                                                                                price={carts.price}
                                                                            />
                                                                        ))}
                                                                        <div className="dropdown-cart-top-footer border-top p-4">
                                                                            {state.total ?
                                                                                <div>
                                                                                    <p className="mb-0 font-weight-bold text-secondary">Sub Total <span className="float-right text-dark">₹{Math.round(Math.abs(state.total.subtotal))}</span></p>
                                                                                </div>
                                                                                : <div></div>
                                                                            }
                                                                            <small className="text-info">Extra charges may apply</small>
                                                                        </div>
                                                                        <div className="dropdown-cart-top-footer border-top p-2">
                                                                            {!T ?
                                                                                <NavDropdown.Item eventKey={5.1} as={Link} className="btn btn-success btn-block py-3 text-white text-center dropdown-item" to="/MobileVerification" onClick={scrollTotop}> Checkout</NavDropdown.Item> :
                                                                                <NavDropdown.Item eventKey={5.1} as={Link} className="btn btn-success btn-block py-3 text-white text-center dropdown-item" to="/checkout" onClick={scrollTotop}> Checkout</NavDropdown.Item>}
                                                                        </div>
                                                                    </div>
                                                                    : <div className="text-center bg-white rounded shadow-sm mb-2">
                                                                        {state.empty}
                                                                    </div>
                                                        }
                                                    </div>
                                                </div>
                                            </NavDropdown> : ""
                                    }
                                    {T ?
                                        <NavDropdown show={show2} onMouseEnter={showDropdown2} onMouseLeave={hideDropdown2}
                                            title={
                                                <DropDownTitle
                                                    className='d-inline-block'
                                                    // faIcon='user-circle'
                                                    image={state.merchant.avatar}
                                                    imageAlt={state.merchant.avatar}
                                                    imageClass="nav-osahan-pic rounded-pill"
                                                    title={state.merchant.full_name}
                                                    onClick={redirect}

                                                />
                                            }
                                        >
                                            <NavDropdown.Item eventKey={4.1} as={NavLink} activeclassname="active" to="/myaccount/orders" onClick={scrollTotop}><Icofont icon='food-cart' /> Orders</NavDropdown.Item>
                                            <NavDropdown.Item eventKey={4.2} as={NavLink} activeclassname="active" to="/myaccount/offers" onClick={scrollTotop}><Icofont icon='sale-discount' /> Offers</NavDropdown.Item>
                                            <NavDropdown.Item eventKey={4.3} as={NavLink} activeclassname="active" to="/myaccount/addresses" onClick={scrollTotop}><Icofont icon='location-pin' /> Addresses</NavDropdown.Item>

                                            <NavDropdown.Item eventKey={4.4} as={NavLink} activeclassname="active" to="/myaccount/Mypoints" onClick={scrollTotop}><Icofont icon='bars' /> My Point</NavDropdown.Item>
                                            <NavDropdown.Item eventKey={4.5} as={NavLink} activeclassname="active" to="/myaccount" onClick={removetoken}><Icofont icon='logout' /> Logout</NavDropdown.Item>
                                        </NavDropdown> : ""}
                                </Nav>
                                {!T ? <div>
                                    <Link className='logout btn btn-outline-primary' to='/login' >Log in</Link>
                                    <Link className='logout btn btn-outline-primary' to='/register'>Sign up</Link>
                                </div> :
                                    <div> </div>
                                }
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </div>
            )}
        </GlobalContext.Consumer>

    );
}

export default Header;
