//Copyright by Chaafo pvt ltd
//List file is to show the items of top offers

import React, { useState, useEffect, useContext } from 'react';
import GlobalContext_ from '../GlobalContext';
import { Row, Col, Container, } from 'react-bootstrap';
import PageTitle from './common/PageTitle';
import CardItem from './common/CardItem';
import { GlobalContext } from './GlobalState';

import { Helmet } from 'react-helmet';
import seoData from '../seo.json';

function TopOffers() {

	//Using contexts of merchant key, top offers and category that are defined globally
	const { MK, topoffers, category, restaurantinfo } = useContext(GlobalContext_);

	const metaData = seoData.data[0].pages.find(page => page.basic_settings.page_name == 'TopOffers')

	const pageTitle = metaData.basic_settings?.tittle
	const basicId = metaData.basic_settings?.basic_id
	const mobileView = metaData.basic_settings?.mobile_view
	const metaDescription = metaData.basic_settings?.meta_desc
	const canonical = metaData.basic_settings?.canonical_tag

	const keywords = metaData.keyword_settings.map((key) => key.keyword)

	const ogTitle = metaData.og_settings?.og_title
	const ogDescription = metaData.og_settings?.og_desc
	const ogSitename = metaData.og_settings?.og_sitename
	const ogImage = metaData.og_settings?.og_image
	const ogImageType = metaData.og_settings?.og_image_type
	const ogImageWidth = metaData.og_settings?.og_image_width
	const ogImageHeight = metaData.og_settings?.og_image_height
	const ogLocale = metaData.og_settings?.og_locale
	const ogURL = metaData.og_settings?.og_url
	const ogType = metaData.og_settings?.og_type
	const ogId = metaData.og_settings?.og_id
	const ogAudio = metaData.og_settings?.og_audio
	const ogVideo = metaData.og_settings?.og_video
	const ogProfile = metaData.og_settings?.og_url
	const ogVideoSecureURL = metaData.og_settings?.og_video_secure_url
	const ogVideoType = metaData.og_settings?.og_video_type
	const ogVideoWidth = metaData.og_settings?.og_video_width
	const ogVideoHeight = metaData.og_settings?.og_video_height
	const ogDeterminer = metaData.og_settings?.og_determiner

	const fbAppId = metaData.og_settings?.fb_app_id
	const fbAuthor = metaData.og_settings?.fb_og_article_author
	const fbPublishTime = metaData.og_settings?.fb_og_article_published_time
	const fbModifyTime = metaData.og_settings?.fb_og_article_modified_time

	const twitterCard = metaData.og_settings?.twitter_card
	const twitterCreator = metaData.og_settings?.twitter_creator
	const twitterImage = metaData.og_settings?.twitter_image
	const twitterURL = metaData.og_settings?.twitter_url
	const twitterSite = metaData.og_settings?.twitter_site
	const twitterDescription = metaData.og_settings?.twitter_desc
	const twitterTitle = metaData.og_settings?.twitter_title


	return (
		<GlobalContext.Consumer>
			{
				(state) => (
					<>
						<Helmet>
							<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
							<title>{pageTitle}</title>
							<link rel="icon" href="/favicon.svg" />
							<link rel="canonical" href={canonical} />
							<meta name="description" content={metaDescription}></meta>
							<meta name="keywords" content={keywords}></meta>
							<meta name="basic-id" content={basicId}></meta>
							<meta name="mobile-view" content={mobileView}></meta>

							{/* og meta tags */}
							<meta property="og:id" content={ogId}></meta>
							<meta property="og:site_name" content={ogSitename}></meta>
							<meta property="og:title" content={ogTitle}></meta>
							<meta property="og:description" content={ogDescription}></meta>
							<meta property="og:url" content={ogURL}></meta>
							<meta property="og:image" content={ogImage}></meta>
							<meta property="og:image:type" content={ogImageType} />
							<meta property="og:image:width" content={ogImageWidth} />
							<meta property="og:image:height" content={ogImageHeight} />
							<meta property="og:type" content={ogType} />
							<meta property="fb:app_id" content={fbAppId} />
							<meta property="og:profile" content={ogProfile} />
							<meta property="og:locale" content={ogLocale} />
							<meta property="og:audio" content={ogAudio} />
							<meta property="og:video:secure:url" content={ogVideoSecureURL} />
							<meta property="og:video:type" content={ogVideoType} />
							<meta property="og:video:width" content={ogVideoWidth} />
							<meta property="og:video:height" content={ogVideoHeight} />
							<meta property="og:determiner" content={ogDeterminer} />
							<meta property="og:video" content={ogVideo} />

							{/* Twitter meta tags */}
							<meta name="twitter:card" content={twitterCard} />
							<meta name="twitter:site" content={twitterSite} />
							<meta name="twitter:title" content={twitterTitle} />
							<meta name="twitter:description" content={twitterDescription} />
							<meta name="twitter:image" content={twitterImage} />
							<meta name="twitter:url" content={twitterURL} />
							<meta name="twitter:creator" content={twitterCreator} />

							{/* Facebook Open Graph Specific Meta Tags (if needed) */}
							<meta property="og:app_id" content={fbAppId} />
							<meta property="og:article:author" content={fbAuthor} />
							<meta property="og:article:published_time" content={fbPublishTime} />
							<meta property="og:article:modified_time" content={fbModifyTime} />
						</Helmet>
						<PageTitle
							title="Top Offers For You"
							subTitle="Best deals at your doorstep"
						/>
						<section className="section pt-5 pb-5 products-listing">
							<Container>
								<Row className="d-none-m">

								</Row>
								<Row>

									<Col md={12}>
										<Row>
											{(topoffers?.map(offers =>
												<Col md={3} sm={6} className="mb-4 pb-2">

													<CardItem
														qty={state?.cart?.find(cartItem => cartItem.item_id === offers.item_id)?.qty ?? 0}

														title={offers.item_name}
														subTitle={offers.item_description || 'Indian • American • Italian'}
														imageAlt={offers.photo}
														image={offers.photo}
														id={offers.item_id}
														imageClass='list item-img'
														linkUrl='detail'
														// offerText='65% off | Use Coupon OSAHAN50'
														// time='15–25 min'
														price={offers.prices.price}
														discountprice={offers.prices.discount_price}
														size={offers.prices.size}
														priceUnit='₹ '
														//showPromoted={true}
														category_id={category.cat_id}
													// promotedVariant='dark'
													// favIcoIconColor='text-danger'
													// rating='3.1 (300+)'
													/>
												</Col>
											))}
											<Col md={12} className="text-center load-more">
											</Col>
										</Row>
									</Col>
								</Row>
							</Container>

						</section>
					</>

				)
			}
		</GlobalContext.Consumer>
	)
}

export default TopOffers;