//Copyright by Chaafo pvt ltd
//Orders is used to show all the past orders of user in my account section

import React, { useState, useEffect, useContext } from 'react';
import OrderCard from '../common/OrderCard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';

import { Helmet } from 'react-helmet';

function Orders() {
    const {MK} = useContext(GlobalContext_);
    var T = localStorage.getItem("tokenn");
    const [Orders, setOrders] = useState([]);


    useEffect(() => {
        //function to show past orders
        async function orders() {

            var myHeaders = new Headers();
            myHeaders.append("Cookie", "_lang=en");

            var formdata = new FormData();
            formdata.append("token", T);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(urls.GetOrders+"?merchant_keys="+MK, requestOptions)
                .then(response => response.json())
                .then(result => setOrders(result.details.data))
        }
        orders()
    },
        [MK]);



    return (
        <>
         <Helmet>
         <meta name="robots" content="noindex, nofollow" />
                    </Helmet>
        
    <ToastContainer />
        {(T && Orders)?
        
            <div className='p-4 bg-white shadow-sm'>
                
                <h4 className="font-weight-bold mt-0 mb-4">Past Orders</h4>
                {(Orders?.map(orders =>
                    <OrderCard
                        image={orders.logo}
                        imageAlt=''
                        orderNumber={orders.order_id}
                        // orderDate={orders.placed}
                        deliveredDate={orders.placed}
                        orderTitle={orders.merchant_name}
                        // address='730 S Mendenhall Rd, Memphis, TN 38117, USA'
                        orderProducts={orders.items.item_name}
                        orderTotal={orders.total}
                        helpLink='#'
                        detailLink='/'
                    />
                ))}
            

            </div>
            :
            <div>
                <div className="mt-2 text-center"><strong>Place your First order!</strong></div>
            </div>
            }
        </>
    );
}

export default Orders;