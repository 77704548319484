//Copyright by Chaafo pvt ltd
// Product box is designed for categories carousel and banner 

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import Shimmer from "react-shimmer-effect";

function ProductBox(props) {

  const { classes } = props;
  return (
    <div className={props.boxClass}>
      <Shimmer>
        <Image src={props.image} className={props.imageClass} alt={props.imageAlt} />
      </Shimmer>

      {props.title ?
        <h6>{props.title}</h6>
        : ""
      }
      {props.counting ?
        <p>{props.counting}</p>
        : ""
      }
    </div>
  );
}

export default ProductBox;