import React, { useState, useEffect, useContext } from 'react';
import { Form, InputGroup, Modal, Button } from 'react-bootstrap';
import Icofont from 'react-icofont';
import { GlobalContext } from '../../components/GlobalState';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { urls } from '../URLs';
import GlobalContext_ from '../../GlobalContext';

function AddAddressModal(props) {
    var myHeaders = new Headers();
    const { MK, locate: loc } = useContext(GlobalContext_);
    var T = localStorage.getItem("tokenn");
    myHeaders.append("Cookie", "_lang=en");
    var formdata = new FormData();
    formdata.append("token", T);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    toast.configure();

    const initialValues = { street: "", city: "", state: "", zipcode: "", location_name: "", country_code: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [show1, setHide1] = useState('');
    const [check, setCheck] = useState(0);
    const [stateid, setStateid] = useState(1);
    const [statelist, setStateList] = useState([]);
    const [citylist, setCityList] = useState([]);
    const { address, setAddress } = useContext(GlobalContext);
    const { cart, setCart, setTotal, setCount, setEmpty, setPay } = useContext(GlobalContext);

    const [location, setLocation] = useState(loc); // State for location

    const resetForm = () => {
        setFormValues({ street: "", city: "", state: "", zipcode: "", location_name: "", country_code: "IN" });
        setFormErrors({});
        setCheck(0);
        setStateid("");
        setStateList([]);
        setCityList([]);
    };
    useEffect(() => {
        if (props.show) {
            resetForm();
        }
    }, [props.show]);

  // Fetch current location when component mounts
    useEffect(() => {
        setLocation(loc);
        resetForm();
    }, [loc]);

    // Function to fetch address book
    async function Address() {
        const response = await fetch(urls.AddressList + '?merchant_keys=' + MK + '&token=' + T);
        const data = await response.json();
        if (data.code == 1) {
            setAddress(data.details.data);
        }
    }

    // Function to load cart after adding address in cart as delivery address
    async function Loadcart() {
        fetch(urls.LoadCart + "?merchant_keys=" + MK + "&token=" + T, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 1) {
                    setCart(result.details.data.item);
                    setTotal(result.details.data.total);
                    setCount(result.details.cart_details);
                    setEmpty('');
                    setPay(result.details);
                }
            })
    }

    // Function to save address in address booklist
    async function AddAddress() {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en")
        var formdata = new FormData();
        formdata.append("street", formValues.street);
        formdata.append("city", formValues.city);
        formdata.append("state", formValues.state);
        formdata.append("zipcode", formValues.zipcode);
        formdata.append("location_name", formValues.location_name);
        formdata.append("country_code", "IN")
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(urls.SaveAddress + "?merchant_keys=" + MK + "&token=" + T, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 1) {
                    Address();
                    Loadcart();
                    toast.success("Address saved", {
                        position: "top-center",
                        autoClose: 400
                    });
                    submitontrue();
                } else {
                    alert("Address not saved");
                }
            })
    }

    // Function to handle changes in fields of add address modal
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleLocationChange = (e) => {
        setLocation(e.target.value);
    };

    // Function to handle errors while submitting the address to get saved
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    };

    const submitontrue = () => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            setHide1(props.onHide);
            return props.onHide
        }
    }

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            AddAddress();
        }
    }, [formErrors]);

    // Function to validate errors
    const validate = (values) => {
        const errors = {};

        if (!values.street) {
            errors.street = "*street is required!";
        } else if (values.street.length < 2) {
            errors.street = "*enter valid street name";
        }
        if (!values.city) {
            errors.city = "*city is required!";
        }
        if (!values.state) {
            errors.state = "*state is required!";
        }
        if (!values.zipcode) {
            errors.zipcode = "*zipcode is required!";
        } else if (values.zipcode.length < 5) {
            errors.zipcode = "*enter valid zipcode";
        }
        if (!values.location_name) {
            errors.location_name = "*location is required";
        }
        return errors;
    };

    // Function to show state list while adding address
    const Statelist = () => {
        fetch(urls.StateList + '?merchant_keys=' + MK)
            .then(response => response.json())
            .then(result => {
                if (result.code == 1) {
                    setStateList(result.details);
                }
            })
    }

    const onChangeHandler = (e) => {
        const index = e.target.selectedIndex;
        const el = e.target.childNodes[index];
        setStateid(el.getAttribute('id'));
    }

    // Function to show City list according to the state chosen
    const Citylist = () => {
        if (stateid) {
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "_lang=en");

            var formdata = new FormData();
            formdata.append("state_id", stateid);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(urls.CityList + "?merchant_keys=" + MK, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code == 1) {
                        setCityList(result.details);
                    }
                })
        }
    }

    // Function to save current location as address in address booklist
    async function AddAddressloc() {
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "_lang=en")
        var formdata = new FormData();
        formdata.append("street", location);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(urls.SaveAddress + "?merchant_keys=" + MK + "&token=" + T, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 1) {
                    Address();
                    Loadcart();
                    toast.success("Address saved", {
                        position: "top-center",
                        autoClose: 400
                    });
                } else {
                    alert("Address not saved");
                }
            })
    }

    return (
        <Modal show={props.show} onHide={props.onHide} centered>
            <Modal.Header closeButton={true}>
                <Modal.Title as='h5' id="add-address">Add Delivery Address</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="form-row">
                        <Form.Check custom type="radio" name="address" id={1} onChange={(e) => setCheck(e.target.id)} label="Use your current location" />
                        <Form.Group className="col-md-12">
                            <Form.Label></Form.Label>
                            <InputGroup>
                                <Form.Control type="text" placeholder="" name="location" value={location} onChange={handleLocationChange} id="location" />
                                <InputGroup.Append>
                                    <Button variant="outline-secondary" type="button" id="button-addon2"><Icofont icon='ui-pointer' /></Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                        <h5 style={{ marginLeft: "116px" }}>OR</h5>
                        <Form.Check custom type="radio" name="address" id={2} onChange={(e) => setCheck(e.target.id)} label="Use custom address" />
                        <Form.Group className="col-md-12">
                            <Form.Label>STREET</Form.Label>
                            <InputGroup>
                                <Form.Control type="text" placeholder="Enter house number, street name, landmark" name="street" value={formValues.street} onChange={handleChange} id="street" />
                            </InputGroup>
                        </Form.Group>
                        <p className='Val'>{formErrors.street}</p>
                        <Form.Group className="col-md-12">
                            <Form.Label>STATE</Form.Label>
                            <Form.Control as="select" onInput={onChangeHandler} onClick={Statelist} type="text" placeholder="Enter your State" name="state" value={formValues.state} onChange={handleChange}  >
                                {(statelist?.map((st) =>
                                    <option id={st.state_id} >{st.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <p className='Val'>{formErrors.state}</p>
                        <Form.Group className="col-md-12">
                            <Form.Label>CITY</Form.Label>
                            <Form.Control as="select" onClick={Citylist} type="text" placeholder="Enter your City" name="city" value={formValues.city} onChange={handleChange} id="city" >
                                {(citylist?.map((ct) =>
                                    <option id={ct.city_id}>{ct.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <p className='Val'>{formErrors.city}</p>
                        <Form.Group className="col-md-12">
                            <Form.Label>ZIPCODE</Form.Label>
                            <Form.Control type="number" placeholder="Enter your Zipcode" maxLength={7} name="zipcode" value={formValues.zipcode} onChange={handleChange} id="zipcode" />
                        </Form.Group>
                        <p className='Val'>{formErrors.zipcode}</p>
                        <Form.Group className="col-md-12">
                            <Form.Label>LANDMARK</Form.Label>
                            <Form.Control type="text" placeholder="Enter your Location" name="location_name" value={formValues.location_name} onChange={handleChange} id="location_name" />
                        </Form.Group>
                        <p className='Val'>{formErrors.location_name}</p>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button type='button' variant="outline-primary" onClick={props.onHide} className="d-flex w text-center justify-content-center">CANCEL</Button>
                {check == 1 ?
                    <Button type='button' variant="primary" className='d-flex w text-center justify-content-center' onClick={AddAddressloc} onClickCapture={props.onHide}>SUBMIT</Button> :
                    <Button type='button' variant="primary" className='d-flex w text-center justify-content-center' onClick={handleSubmit} >SUBMIT</Button>}
            </Modal.Footer>
        </Modal>
    );
}

export default AddAddressModal;
